import React from 'react'
import cx from 'classnames'
import { Form } from 'antd'
import type { FormItemProps, Rule } from 'antd/lib/form'
import { isNil, pathOr } from 'ramda'
import useViewport from '@shared/innmaxLib/hooks/useViewport'
import Input from '@shared/Form/Input'
import { Column } from '@shared/Layout'
import styled from 'styled-components'

const RequiredTextStyled = styled.span`
  position: relative;
  top: 3px;
  color: ${p => p.theme.form.isRequired};
  margin-left: 2px;
`

export const Asterisk = ({ className }: { className?: string }) => (
  <RequiredTextStyled className={cx(className)}>*</RequiredTextStyled>
)

interface FieldProps extends FormItemProps {
  // name: string
  value?: any
  isEdit?: boolean
  children: React.ReactNode
  // rules?: Rule[]
}

export const Field = ({
  name,
  value,
  isEdit,
  children,
  ...props
}: FieldProps) => {
  if (!isEdit) {
    return value
  }

  return (
    <Form.Item noStyle name={name} {...props}>
      {children}
    </Form.Item>
  )
}

interface RenderFieldProps extends Omit<FormFieldsProps, 'rules'> {
  rules?: Rule[]
}

interface FormFieldsProps {
  title: string[]
  field?: string[]
  requiredField?: any[]
  rules?: [Rule[], Rule[]]
  className?: any
  isEdit?: boolean
  editable?: boolean
  item?: any
  renderLeft?: (props: RenderFieldProps) => React.ReactNode
  renderRight?: (props: RenderFieldProps) => React.ReactNode
}

export function TableColumn(props: FormFieldsProps) {
  const {
    title,
    field = [],
    requiredField = [false, false],
    rules = [],
    renderLeft,
    renderRight,
    editable = true,
    className,
    isEdit,
    item,
  } = props

  const { isMobile } = useViewport()

  const ColumnField = Column.White

  const [title1, title2] = title
  const [field1, field2] = field
  const [rules1, rules2] = rules
  const [required_field1, required_field2] = requiredField

  const render = (
    name: string,
    renderer?: (props: RenderFieldProps) => React.ReactNode,
    rules?: Rule[]
  ) => {
    if (renderer) {
      return renderer({ ...props, rules: rules as any })
    }
    if (isEdit && editable) {
      return (
        <Form.Item noStyle name={name} rules={rules}>
          <Input className="w-full" />
        </Form.Item>
      )
    }

    return pathOr('', [name], item)
  }

  return isMobile ? (
    <>
      <div className="flex-1 flex mobile-col">
        <ColumnField>
          {title1}
          {required_field1 && <Asterisk />}
        </ColumnField>
        <ColumnField className={cx('flex-1', className)}>
          {render(field1, renderLeft, rules1)}
        </ColumnField>
      </div>

      {!isNil(title2) && (
        <div className="flex-1 flex mobile-col">
          <ColumnField>
            {title2}
            {required_field2 && <Asterisk />}
          </ColumnField>
          <ColumnField className={cx('flex-1', className)}>
            {render(field2, renderRight, rules2)}
          </ColumnField>
        </div>
      )}
    </>
  ) : (
    <div className="flex">
      <ColumnField>
        {title1}
        {required_field1 && <Asterisk />}
      </ColumnField>
      <ColumnField className={cx('flex-1', className)}>
        {render(field1, renderLeft, rules1)}
      </ColumnField>
      {!isNil(title2) && (
        <>
          <ColumnField>
            {title2}
            {required_field2 && <Asterisk />}
          </ColumnField>
          <ColumnField className={cx('flex-1', className)}>
            {render(field2, renderRight, rules2)}
          </ColumnField>
        </>
      )}
    </div>
  )
}
