import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const RequiredTextStyled = styled.div`
  color: ${p => p.theme.form.isRequired};
  margin-bottom: 8px;
`

export default function FormRequiredTip() {
  const { t } = useTranslation()

  return (
    <RequiredTextStyled>
      {t('device:form_tip_field_required')}
    </RequiredTextStyled>
  )
}
