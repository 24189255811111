import React from 'react'
import { useRecoilState } from 'recoil'
import { CRS, LatLngBoundsExpression, Map } from 'leaflet'

import { Device } from '@services/device'
import { MapActiveMarkersState, MapActiveMarkerState } from '../map.state'
import { WMSTileLayer } from 'react-leaflet'

type ContextType = {
  map: Map | null
  isLoaded?: boolean
  handleMapLoad: (map: Map) => any
  handleActiveMarkers: (devices: Device[]) => void
  handleActiveMarker: (device: Device | undefined) => void
  mapBounds: LatLngBoundsExpression
  mapBoundsForPopupOpened: LatLngBoundsExpression
  setMapBound: (bound: LatLngBoundsExpression) => any
  mapLayers: typeof WMSTileLayer[]
  activedLayer: typeof WMSTileLayer
  setActiverLayer: (layer: typeof WMSTileLayer) => void
}

const MapContext = React.createContext<ContextType>({
  map: null,
  handleMapLoad: (map: Map) => ({}),
  handleActiveMarkers: (devices: Device[]) => {},
  handleActiveMarker: (device: Device | undefined) => {},
  mapBounds: [],
  mapBoundsForPopupOpened: [],
  setMapBound: (bound: LatLngBoundsExpression) => ({}),
  mapLayers: [],
  activedLayer: {} as any,
  setActiverLayer: () => {},
})

export function LeafletProvider(props: any) {
  const [map, setMap] = React.useState<Map | null>(null)

  const [isLoaded, setIsLoaded] = React.useState(false)

  const [activeMarkers, handleActiveMarkers] = useRecoilState(
    MapActiveMarkersState
  )

  const [, handleActiveMarker] = useRecoilState(MapActiveMarkerState)

  const [mapBounds, setMapBound] = React.useState<LatLngBoundsExpression>([
    [0, 0],
    [500, 500],
  ])

  const [mapBoundsForPopupOpened] = React.useState<LatLngBoundsExpression>([
    [-300, -600],
    [1360, 1660],
  ])

  const [mapLayers] = React.useState([
    <WMSTileLayer
      url="https://wms.nlsc.gov.tw/wms?"
      params={{
        layers: 'EMAP',
      }}
      uppercase
      crs={CRS.EPSG3857}
    />,
    <WMSTileLayer
      url="https://wms.nlsc.gov.tw/wms?"
      params={{
        layers: 'PHOTO2',
      }}
      uppercase
      crs={CRS.EPSG3857}
    />,
  ])

  const [activedLayer, setActiverLayer] = React.useState(mapLayers[0])

  const handleMapLoad = React.useCallback((mapInstance: Map) => {
    setMap(mapInstance)
    setIsLoaded(true)
  }, [])

  let value = React.useMemo(
    () => ({
      map,
      handleMapLoad,
      handleActiveMarkers,
      handleActiveMarker,
      isLoaded,
      setMapBound,
      mapBounds,
      mapBoundsForPopupOpened,
      mapLayers,
      setActiverLayer,
      activedLayer,
    }),
    [map, mapBounds, isLoaded, activeMarkers, activedLayer] // eslint-disable-line
  )

  return <MapContext.Provider value={value} {...props} />
}

export function useLeaflet() {
  const context = React.useContext(MapContext)
  if (!context) {
    throw new Error('useLeaflet must be within a MapProvider')
  }

  return context
}

export default useLeaflet
