import { SVGProps } from 'react'
import cx from 'classnames'

const MoneySVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('tb-icon-money', className)}
    {...props}>
    <path
      d="M19.9997 36.6666C10.7947 36.6666 3.33301 29.2049 3.33301 19.9999C3.33301 10.7949 10.7947 3.33325 19.9997 3.33325C29.2047 3.33325 36.6663 10.7949 36.6663 19.9999C36.6663 29.2049 29.2047 36.6666 19.9997 36.6666ZM14.1663 23.3333V26.6666H18.333V29.9999H21.6663V26.6666H23.333C24.4381 26.6666 25.4979 26.2276 26.2793 25.4462C27.0607 24.6648 27.4997 23.605 27.4997 22.4999C27.4997 21.3949 27.0607 20.335 26.2793 19.5536C25.4979 18.7722 24.4381 18.3333 23.333 18.3333H16.6663C16.4453 18.3333 16.2334 18.2455 16.0771 18.0892C15.9208 17.9329 15.833 17.7209 15.833 17.4999C15.833 17.2789 15.9208 17.0669 16.0771 16.9107C16.2334 16.7544 16.4453 16.6666 16.6663 16.6666H25.833V13.3333H21.6663V9.99992H18.333V13.3333H16.6663C15.5613 13.3333 14.5015 13.7722 13.7201 14.5536C12.9387 15.335 12.4997 16.3949 12.4997 17.4999C12.4997 18.605 12.9387 19.6648 13.7201 20.4462C14.5015 21.2276 15.5613 21.6666 16.6663 21.6666H23.333C23.554 21.6666 23.766 21.7544 23.9223 21.9107C24.0785 22.0669 24.1663 22.2789 24.1663 22.4999C24.1663 22.7209 24.0785 22.9329 23.9223 23.0892C23.766 23.2455 23.554 23.3333 23.333 23.3333H14.1663Z"
      fill="#FFCC2B"
    />
  </svg>
)

export default MoneySVG