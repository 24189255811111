import React from 'react'
import { useQuery, useMutation } from 'react-query'
import {
  isEmpty,
  gt,
  pathOr,
  sum,
  prop,
  sortWith,
  descend,
  forEach,
  isNil,
} from 'ramda'
import Store from 'store'
import {
  convertData,
  request,
  QueryVariables,
  useLazyQuery,
} from '@shared/innmaxLib/services'
import { findValue } from '@shared/innmaxLib/utils/webHelper'
import {
  YesNoEnums,
  useAlarmConverter,
  Alarm,
  AlarmType,
  Notify,
  NotifyEmail,
  SessionInfo,
} from '@services/alarm'
import { activeEnums, isPopUpEnums } from './alarm.enums'
import envStore from '@shared/env'
import { useAllSessions } from '@services/session'

export const createAlarmQuery = (values: Alarm) =>
  request('/alarmSetting', { method: 'POST', body: values })

export const updateAlarmQuery = (values: Alarm) =>
  request('/alarmSetting/id/{id}', { method: 'PUT', body: values })

export const deleteAlarmQuery = (values: any) =>
  request('/alarmSetting/id/{id}', { method: 'DELETE', body: values })

export const getNotifyEmailsQuery = (values: any) =>
  request(
    '/system/notifySetting/email/{args.params.current}/{args.params.pageSize}'
  )

export const updateNotifyQuery = (values: Notify) =>
  request('/system/notifySetting', { method: 'POST', body: values })

export const updateNotifyEmailsQuery = (values: any) =>
  request('/system/notifySetting/email', { method: 'PUT', body: values })

export function useQueryAlarms({
  variables = { pageNum: 0, pageSize: 10 },
  ...options
}) {
  const { data, ...others } = useQuery(
    ['/alarmSetting/list', { ...variables }],
    { ...options, cacheTime: 0 }
  )

  const { alarmTypeByKey } = useAlarmTypes()

  const dataSource = convertData<Alarm>(
    {
      field: ['data'],
      converter: (x: Alarm) => {
        const alarm = alarmTypeByKey[x.alarmType]
        return {
          ...x,
          isPopUpStr: isPopUpEnums[`${x.isPopUp}`],
          alarmTypeStr: alarm?.desc,
          alarmUnitStr: alarm?.unit,
          activeStr: activeEnums[x.active],
        }
      },
    },
    data
  )

  return { dataSource, ...others }
}

export const useAlarmById = (
  query: {
    [key: string]: any
  },
  options: QueryVariables = {}
) => {
  const { data, ...others } = useQuery(['/alarmSetting/id/{id}', query], {
    ...options,
    enabled: !isNil(query?.id),
    cacheTime: 0,
  })
  const { alarmTypeByKey } = useAlarmTypes()
  const { data: SessionsData } = useAllSessions()

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return {}
    }

    const _data = pathOr({}, ['data'], data) as any
    const alarm = alarmTypeByKey[_data.alarmType]
    const _sessions = _data?.sessionSeqs?.map(
      (ele: any) => SessionsData.find(ele2 => ele2.id === ele)?.name
    )

    return {
      ..._data,
      isPopUpStr: isPopUpEnums[`${!_data.isPopUp}`],
      alarmTypeStr: alarm?.desc,
      alarmUnitStr: alarm?.unit,
      activeStr: activeEnums[_data.active],
      sessionSeqsStr: _sessions,
    }
  }, [data])

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export function useCreateAlarm(values: any) {
  const { isLoading, mutate } = useMutation(createAlarmQuery, values)

  return {
    creating: isLoading,
    handleAdd: mutate,
  }
}

export function useDeleteAlarm(values: any) {
  const { isLoading, mutate } = useMutation(deleteAlarmQuery, values)

  return {
    deleting: isLoading,
    handleDelete: mutate,
  }
}

export function useUpdateAlarm(values: any) {
  const { isLoading, mutate } = useMutation(updateAlarmQuery, values)

  return {
    updating: isLoading,
    handleUpdate: mutate,
  }
}

export function useQueryNotify(variables?: any) {
  const { data, isLoading, ...others } = useQuery('/system/notifySetting', {
    ...variables,
  })

  const dataSource = React.useMemo(
    () => pathOr({}, ['data'], data) as Notify,
    [data]
  )

  return {
    loading: isLoading,
    data: dataSource,
    ...others,
  }
}

export function useUpdateNotify(values: any) {
  const { isLoading, mutate } = useMutation(updateNotifyQuery, values)

  return {
    updating: isLoading,
    handleUpdate: mutate,
  }
}

export function useQueryNotifyEmails({
  variables,
  ...options
}: QueryVariables = {}) {
  const { data, isLoading, ...others } = useQuery(
    ['/system/notifySetting/email/{pageNum}/{pageSize}', variables],
    {
      ...options,
    }
  )

  const dataSource: any = React.useMemo(
    () =>
      convertData(
        {
          field: ['data'],
          converter: (x: NotifyEmail) => x,
        },
        data
      ),
    [data]
  )

  return { dataSource, loading: isLoading, ...others }
}

export function useUpdateNotifyEmails(options: any) {
  const { isLoading, mutate } = useMutation(updateNotifyEmailsQuery, options)

  return {
    updating: isLoading,
    handleUpdateEmails: mutate,
  }
}

export const useAlarmTypes = () => {
  const [current, setCurrent] = React.useState<AlarmType>()

  const { data, ...others } = useQuery(['/alarmSetting/types'], {
    cacheTime: 0,
  })

  const alarmTypes = pathOr([], ['data'], data) as AlarmType[]

  const alarmTypeByKey = React.useMemo(() => {
    let alarmTypeByKey: { [key: string]: AlarmType } = {}

    forEach((x: AlarmType) => (alarmTypeByKey[`${x.value}`] = x), alarmTypes)

    return alarmTypeByKey
  }, [data])

  const handleChange = React.useCallback((value: any) => {
    const type = value
      ? (findValue(value, 'value')(alarmTypes) as AlarmType)
      : undefined

    setCurrent(type)
  }, [])

  return {
    data,
    current,
    alarmTypes,
    alarmTypeByKey,
    handleChange,
    ...others,
  }
}

export const useAlarmList = (
  query?: {
    [key: string]: any
  },
  options: QueryVariables = {}
) => {
  const convertAlarm = useAlarmConverter()

  const { data, ...others } = useQuery(['/alarm/search', query], options)

  const sorting: any = sortWith([descend(prop('createAt') as any)])

  const dataSource = React.useMemo(
    () =>
      convertData(
        {
          converter: convertAlarm,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  return {
    ...others,
    dataSource: sorting(pathOr<Alarm[]>([], ['content'], dataSource)),
    loading: others.isLoading,
  }
}

/**
 * 即時警報
 */
export const useAlarmNotification = ({
  variables,
  ...options
}: QueryVariables = {}) => {
  const convertAlarm = useAlarmConverter()

  const { data, ...others } = useQuery(
    ['/alarm/search', variables],
    options as any
  )

  const sorting: any = sortWith([descend(prop('createAt') as any)])

  const dataSource = React.useMemo(() => {
    // 顯示 alarmMessage 過濾條件
    const _data = pathOr<Alarm[]>([], ['data'], data).filter(
      (a: Alarm) => a.isPopUp && a.status === 1
    )

    if (isEmpty(_data)) {
      return []
    }

    const alarms = sorting(_data)

    const oldAlarms = Store.get(`${envStore.storageKeyPrefix}_alarmMessage`)

    if (!oldAlarms || isEmpty(oldAlarms)) {
      if (!isEmpty(alarms)) {
        const values = alarms.map(convertAlarm)
        Store.set(`${envStore.storageKeyPrefix}_alarmMessage`, values)
        return values
      }
    }

    const oldAlarmIds = oldAlarms.map((a: Alarm) => a.id)
    const updateAlarms = alarms.filter((a: Alarm) => gt(a.id, oldAlarmIds[0]))

    if (isEmpty(updateAlarms)) {
      return []
    }

    const values = updateAlarms.map(convertAlarm)
    Store.set(`${envStore.storageKeyPrefix}_alarmMessage`, values)

    return values
  }, [JSON.stringify(data)])

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useAlarmSearch = (
  query?: {
    [key: string]: any
  },
  options: QueryVariables = {}
) => {
  const convertAlarm = useAlarmConverter()

  const { data, ...others } = useLazyQuery(['/alarm/search', query], options)

  const sorting: any = sortWith([descend(prop('createAt') as any)])

  const dataSource = React.useMemo(
    () =>
      convertData(
        {
          converter: convertAlarm,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  return {
    ...others,
    dataSource: sorting(pathOr<Alarm[]>([], ['content'], dataSource)),
    loading: others.isLoading,
  }
}

export function useCheckAlarm() {
  const { isLoading, mutate } = useMutation(
    (values: { check: boolean; id: number }[]) =>
      request('/alarm/check', { method: 'PUT', body: values })
  )

  return {
    isCheckNow: isLoading,
    handleCheck: mutate,
  }
}
