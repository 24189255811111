import React from 'react'
import { renderToString } from 'react-dom/server'
import { Layout, Button, Tooltip } from 'antd'
import UserOutlined from '@ant-design/icons/UserOutlined'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { useAuth } from '@services/auth'
import { usePageHeader } from '@shared/innmaxLib/hooks/usePageHeader'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@shared/innmaxUI/Dropdown'

type Props = {
  title?: string | React.ReactNode
}

function Navbar({ title }: Props) {
  const { logout, user } = useAuth()

  const handleLogout = () => logout()

  const { t } = useTranslation()

  const userDisplayName = pathOr('', ['displayName'], user);

  React.useEffect(() => {
    ; (document.getElementById('header-title') as HTMLElement).innerHTML =
      renderToString(title as React.ReactElement<React.ReactNode>)
  }, [JSON.stringify((title as any)?.props)]) //eslint-disable-line

  return (
    <Header>
      <div id="header-title" />
      <div className="inline-flex items-center">
        {usePageHeader().content}
        <Dropdown
          menu={[
            {
              key: 'User',
              label:
                <>
                  <Tooltip placement="left" title={userDisplayName}>
                    <UserOutlined style={{ fontSize: '16px', padding: '2px' }} />
                    {userDisplayName}
                  </Tooltip>
                </>,
              style: { textAlign: 'center', color: '#A1DDFF' },
              disabled: true,
              className: 'dropdown-userDisplayName'
            },
            {
              key: 'SignOut',
              label: t('common:logout'),
              onClick: handleLogout,
              style: { textAlign: 'center' },
            },
          ]}>
          <Button type="link" className="ant-dropdown-link p-0">
            <LoginUser>
              <UserOutlined className="icon-user" />
            </LoginUser>
          </Button>
        </Dropdown>
        {usePageHeader().contentRight}
      </div>
    </Header>
  )
}

const Header = styled(Layout.Header)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &.ant-layout-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 16px;
    background: transparent;
    line-height: initial;
  }
`

const LoginUser = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: normal;

  .icon-user{
    font-size: 24px;
    padding: 3px 4px;
    opacity: 0.5;
    flex-shrink: 0;
    margin-bottom:3px;

    svg{
      width: 21px;
      height: 21px;
    }
  }
`

export default Navbar
