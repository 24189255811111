import styled from 'styled-components'
import theme from '@theme'

const H1 = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  color: ${theme.font.primary};

  .sub-title {
    // color: #41a4ff;
    color: rgba(255, 255, 255, 0.5);
  }
`

export default H1
