import { SVGProps } from 'react'

const Option = (props: SVGProps<any>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M12 19H7.99999L7.44899 16.52C6.79324 16.2636 6.17999 15.9096 5.62999 15.47L3.20599 16.233L1.20599 12.769L3.07799 11.051C2.97323 10.3549 2.97323 9.64708 3.07799 8.951L1.20599 7.232L3.20599 3.768L5.62999 4.531C6.18024 4.09097 6.79384 3.73664 7.44999 3.48L7.99999 1H12L12.551 3.48C13.2067 3.73642 13.82 4.09041 14.37 4.53L16.794 3.767L18.794 7.231L16.922 8.949C17.0268 9.64507 17.0268 10.3529 16.922 11.049L18.794 12.767L16.794 16.231L14.37 15.468C13.8201 15.9083 13.2069 16.263 12.551 16.52L12 19Z" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" stroke="white" stroke-width="1.6" />
    </svg>
)

export default Option
