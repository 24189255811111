import styled from 'styled-components'
import { Form } from 'antd'

const StyledForm = styled(Form)`
  .ant-form-item .ant-form-item-label {
    padding: 0 0 4px;

    label {
      font-size: 12px;
      color: ${p => p.theme.form.label};
    }
  }

  .ant-input-affix-wrapper-status-error{
    background-color:${p => p.theme.input.bg} !important;
  }
`

export default StyledForm
