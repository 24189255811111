import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import {
  useConvertChargingPoint,
  useConvertChargingPointDetail,
} from './chargingpoint.converter'
import type {
  QueryVariables,
  MutationOptions,
} from '@shared/innmaxLib/services'
import {
  request,
  useMakeMutation,
  useLazyQuery,
} from '@shared/innmaxLib/services'
import { path, map, prop } from 'ramda'
import { PAGE_SIZE, TYPE, xAxisName } from '@services/report'
import { formatDate, isNil } from '@shared/utils/webHelper'

export const useChargingPoint = (query?: any, options?: UseQueryOptions) => {
  const { data, ...others } = useQuery(
    ['/device/chargingpoint/{pageNum}/{pageSize}', query],
    {
      cacheTime: 0,
      ...options,
    }
  )

  const dataSource = useConvertChargingPoint(data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useLazyQueryChargingPoint = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, refetch, isLoading, ...others } = useLazyQuery(
    [`/device/chargingpoint/{id}`, variables],
    {
      ...options,
      select: (res: any) => path(['data'], res) as any,
    }
  )

  const dataSource = useConvertChargingPointDetail(data as any) as any

  return {
    ...others,
    refetch: () => refetch({}),
    queryChargingPoint: refetch,
    loading: isLoading,
    data: dataSource,
  }
}

export const createChargingPoint = (values: Partial<any>) =>
  request('/device/chargingpoint', { method: 'POST', body: values })

export const updateChargingPoint = ({ id, ...values }: Partial<any> = {}) =>
  request(`/device/chargingpoint/${id}`, { method: 'PUT', body: values })

export const deleteChargingPoint = (id: React.Key) =>
  request(`/device/chargingpoint/${id}`, { method: 'DELETE' })

export const useAddChargingPoint = (options: MutationOptions = {}) => {
  return useMakeMutation(createChargingPoint, options)
}

export const useUpdateChargingPoint = (options: MutationOptions = {}) => {
  return useMakeMutation(updateChargingPoint, options)
}

export const useDeleteChargingPoint = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteChargingPoint, options)
}

export const usePaymentType = (query?: { [key: string]: any }) => {
  const { data, isLoading, refetch, ...other } = useQuery([
    '/device/chargingpoint/report/paymentType',
    query,
  ])

  return { dataSource: data, isLoading, refetch, ...other }
}

export const useChargingPointTypes = (query?: { [key: string]: any }) => {
  const { data, isLoading, refetch, ...other } = useQuery([
    '/device/chargingpoint/types',
    query,
  ])

  return { dataSource: data, isLoading, refetch, ...other }
}

export const useChargingPaymentByDay = (query: { [key: string]: any }) => {
  const { data, isLoading, refetch, ...other } = useQuery([
    '/device/chargingpoint/report/chargingPaymentByDay',
    query,
  ])

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return { totalPages: 0, total: 0, content: [] }
    }

    const { reportType } = query

    const contentData = (
      path(['data', 'deviceChargePayment'], data) as any
    ).map((i: any) => {
      let displayDate: any = ''
      switch (reportType) {
        case TYPE.YEAR:
          const m = new Date(i.date).getMonth()
          displayDate = xAxisName(m)
          break
        case TYPE.MONTH:
          displayDate = `${new Date(i.date).getDate()}日`
          break
        case TYPE.DAY:
          displayDate = formatDate(new Date(`${i.date}:00:00`).valueOf(), 'HH')
          break
        default:
          break
      }

      return { ...i, displayDate }
    })

    const total = contentData.length
    const kwh = map(prop('kwh'), contentData || [])
    const tranAmt = map(prop('tranAmt'), contentData || [])

    return {
      xAxisLabels: contentData.map((i: any) => i.displayDate),
      total,
      totalPages: Math.ceil(total / PAGE_SIZE),
      kwh,
      tranAmt,
    }
  }, [data, query])

  return { dataSource, isLoading, refetch, ...other }
}

export const useChargingPaymentByDevice = (query?: { [key: string]: any }) => {
  const { data, isLoading, refetch, ...other } = useQuery([
    '/device/chargingpoint/report/chargingPaymentByDevice',
    query,
  ])

  return { dataSource: data, isLoading, refetch, ...other }
}
