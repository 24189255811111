import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import styled from 'styled-components'
import { RefProps } from '@lib/types'

type Props = DatePickerProps & RefProps

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 4px;
    background-color: ${p => p.theme.input.bg};
    border-color: transparent;

    ::placeholder {
      color: ${p => p.theme.input.placeholderColor};
    }

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primary};
    }

    &:focus {
      box-shadow: none;
    }

    > .ant-picker-input {
      > input,
      > .ant-picker-suffix {
        color: #fff;
      }
    }
  }
`

// for date picker dropdown panel styles
const PanelRenderStyle = styled.div`
  background-color: ${p => p.theme.datePicker.panelBg}; /* Set the background color */
  color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.70));

  .ant-picker-header{
    color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    > button{
      color: ${p => p.theme.datePicker.arrowColor};/* Set the arrow color */
    }
  }

  .ant-picker-cell{
    color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
  }

  .ant-picker-cell-disabled{
    opacity: ${p => p.theme.datePicker.disableOpacity}; /* Set the disabled text opacity */
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background-color: ${p => p.theme.datePicker.hoverColor};/* Set the hover bg color */
    color: ${p => p.theme.datePicker.panelTextColor}; /* Set the hover text color */
  }

  .ant-picker-content {
    th{
      color: ${p => p.theme.datePicker.panelTextColor}; /* Set the week text color */
    }
  }
`

const DatePickerComponent = React.forwardRef<typeof DatePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <StyledDatePicker
      // forwardRef={ref}
      className={cx('c-datepicker', className)}
      placeholder=""
      panelRender={(panel) => (
        <PanelRenderStyle>
          {panel}
        </PanelRenderStyle>
      )}
      {...props}
    />
  )
)

DatePickerComponent.displayName = 'DatePicker'

export default DatePickerComponent

