import ReactDOM from 'react-dom/client'
import renderer from '@shared/innmaxLib/utils/renderer'
import { envStore } from '@shared/env'
import App from './App'
import reportWebVitals from './reportWebVitals'

// @ts-ignore
__webpack_nonce__ = envStore.cspNonce

// @ts-ignore
window.recaptchaOptions = {
  nonce: envStore.cspNonce,
}

if (envStore.isEnvDev) {
  // const { server } = require('../../mocks')
  // server.start({
  //   serviceWorker: {
  //     url: `${envStore.publicUrl}/mockServiceWorker.js`,
  //   },
  // })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(renderer()(App))

// root.render(
//   <React.StrictMode>
//     {renderer()(App)}
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
