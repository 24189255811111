import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import moment from 'moment'
import { Row, Col, Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import Box from '@shared/Box'
import theme from '@theme'

import { PAGE_SIZE, TYPE, useAlarmRpt, dateTimeFormat } from '@services/report'
import { initAlarmBarChart, tooltipPointFormat } from '../ReportChartConfig'
import ChartTitle from '../ChartTitle'
import Pagination from '../Pagination'
import type { IAlarmProps } from '../type'

import {
  always,
  cond,
  equals,
  flatten,
  groupBy,
  keys,
  prop,
  range,
  toPairs,
} from 'ramda'
import { Space } from 'antd'
import { INTERVAL_TYPE } from '@services/report'
import type { ICommonProps } from '../type'
import { Column, Line, Plot } from '@ant-design/charts'
import { toThousandSeparator } from '@shared/innmaxLib/utils/webHelper'
import { css } from 'styled-components'
import Report from 'src/components/Report'
import { Alarm } from '@services/alarm'
import { useSessionDetail } from '@services/session'

export function AlarmChart(props: any) {
  const { queryVariables, yAxisDateStr, dataSource, loading } = props

  const { t } = useTranslation()

  const [currentChart, setCurrentChart] = React.useState(0)
  const query = React.useMemo(() => queryVariables, [queryVariables])

  const { dataSource: sessionIdData } = useSessionDetail({
    id: query?.sessionId,
  })

  // 分頁
  const [startIdx, setStartIdx] = React.useState(0)

  const groupByDate = React.useMemo(() => {
    return groupBy(
      (alarm: Alarm) => alarm?.createAt?.format('YYYY-MM'),
      dataSource
    )
  }, [dataSource]) // eslint-disable-line

  const chartData = React.useMemo(() => {
    const endIdx = startIdx + PAGE_SIZE

    return flatten(
      toPairs(groupByDate)
        .sort(([a], [b]) => moment(a).valueOf() - moment(b).valueOf())
        .slice(startIdx, endIdx)
        .map(([key, value]) => {
          const normalAlarm = value.filter(alarm => !alarm.isPopUp).length
          const criticalAlarm = value.filter(alarm => alarm.isPopUp).length
          return [
            {
              date: key,
              value: normalAlarm,
              category: '一般警報',
            },
            {
              date: key,
              value: criticalAlarm,
              category: '嚴重警報',
            },
          ]
        })
    )
  }, [groupByDate, startIdx])

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }
    setStartIdx(0)
    setCurrentChart(0)
  }, [query])

  const chartConfig = {
    data: chartData,
    xField: 'date',
    yField: 'value',
  }

  const plotRef = React.useRef<Plot<any> | null>(null)

  return (
    <div>
      <Report.ChartLayout
        loading={loading}
        pagination={{
          totalSize: (keys(groupByDate) || []).length,
          onStartIndexChange: setStartIdx,
          onCurrentPageIndexChange: setCurrentChart,
          currentPageIndex: currentChart,
          startIndex: startIdx,
        }}
        yAxisTitle="警報次數"
        chartTitle={{
          title: yAxisDateStr,
          subTitle: `${sessionIdData?.data.name || '全區'} 充電樁`,
        }}>
        <Column
          {...chartConfig}
          legend={false}
          dodgePadding={2}
          onReady={p => (plotRef.current = p)}
          seriesField="category"
          color={['#20E4FF', '#E17567']}
          isStack
          autoFit
          maxColumnWidth={20}
          tooltip={{
            title: (title, { date }) => {
              const _date = date.split('-')
              return cond([
                [
                  equals(INTERVAL_TYPE.YEAR),
                  always(`${_date[0] + '/' + _date[1]}`),
                ],
                [equals(INTERVAL_TYPE.MONTH), always(`${_date[2]}號`)],
                [equals(INTERVAL_TYPE.DAY), always(`${date}`)],
              ])(query.reportType)
            },
            formatter: (datum: any) => {
              return {
                name: datum.category as any,
                value: toThousandSeparator(datum.value) as any,
              }
            },
          }}
          xAxis={{
            title: {
              text: yAxisDateStr,
              position: 'end',
              style: {
                fill: '#fff',
              },
              autoRotate: false,
            },
            tickLine: {
              style: {
                width: 1,
              },
            },
            tickMethod: (tickCount: any) => {
              const groupByDate = groupBy(
                (alarm: Alarm) => alarm.createAt.format('YYYY-MM'),
                dataSource
              )
              return keys(groupByDate).map(date =>
                moment(date, 'YYYY-MM')
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .millisecond(0)
                  .valueOf()
              )
            },
            type: 'timeCat',
            label: {
              style: {
                fill: '#fff',
              },
              formatter: (text, item) => {
                return cond([
                  [
                    equals(INTERVAL_TYPE.YEAR),
                    always(moment(item.id, 'x').format('MM月')),
                  ],
                  [
                    equals(INTERVAL_TYPE.MONTH),
                    always(moment(text).format('D日')),
                  ],
                  [
                    equals(INTERVAL_TYPE.DAY),
                    always(moment(item.id, 'x').format('YYYY-MM-DD')),
                  ],
                ])(query.reportType)
              },
            },
          }}
          yAxis={{
            title: {
              text: '次',
              position: 'end',
              style: {
                fill: '#fff',
              },
              autoRotate: false,
            },
            label: {
              formatter: text => {
                return toThousandSeparator(text)
              },
              style: {
                fill: '#fff',
              },
            },
            grid: {
              line: {
                style: {
                  fill: '#fff',
                  opacity: 1,
                  lineWidth: 2,
                },
              },
            },
          }}
        />
      </Report.ChartLayout>
      <Report.Legend.Provider plotRef={plotRef} filterProp="category">
        <div className="flex justify-center">
          <Space>
            {[
              {
                name: '一般警報',
                color: '#20E4FF',
              },
              {
                name: '嚴重警報',
                color: '#E17567',
              },
            ].map(d => (
              <Report.Legend.Item defaultActive key={d.name} data={d} />
            ))}
          </Space>
        </div>
      </Report.Legend.Provider>
    </div>
  )
}

export default React.memo(AlarmChart)
