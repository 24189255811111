import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import moment from 'moment'
import { Row, Col, Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import Box from '@shared/Box'
import theme from '@theme'

import { PAGE_SIZE, TYPE, dateTimeFormat } from '@services/report'
import {
  initColumnChart,
  tooltipHeaderFormat,
  tooltipPointFormat,
} from '../ReportChartConfig'
import ChartTitle from '../ChartTitle'
import Pagination from '../Pagination'
import type { ICommonProps } from '../type'

export function LinkRateChart(props: ICommonProps) {
  const { queryVariables, yAxisDateStr, dataSource, loading } = props
  const { t } = useTranslation()

  const [startIdx, setStartIdx] = React.useState(0)
  const [currentChart, setCurrentChart] = React.useState(0)
  const query = React.useMemo(() => queryVariables, [queryVariables])
  const [options, setOptions] = React.useState(initColumnChart)

  const chart = React.useRef<HighchartsReact.RefObject>(null)

  React.useEffect(() => {
    const endIdx = startIdx + PAGE_SIZE

    setOptions((x: any) => ({
      ...x,
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      xAxis: {
        ...initColumnChart.xAxis,
        type: 'datetime',
        labels: {
          style: {
            fontSize: '10px',
            color: theme.report.color,
          },
          formatter: function (this: any) {
            return dateTimeFormat(query.reportType, this.value)
          },
        },
      },
      yAxis: [
        {
          ...initColumnChart.yAxis,
          opposite: false,
          labels: {
            enabled: true,
          },
          title: {
            ...initColumnChart.yAxis.title,
            enabled: true,
            text: t('report:connectRate yAxis Label'),
          },
        },
      ],
      tooltip: {
        ...initColumnChart.tooltip,
        formatter: function (): any {
          const _this = this as any
          return (this as any).points.reduce(function (s: any, point: any) {
            return s + tooltipPointFormat(point, true, '%')
          }, tooltipHeaderFormat(moment(_this?.x).format('YYYY.MM.DD')))
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('report:connectRate'),
          type: 'column',
          data: dataSource?.chart?.slice(startIdx, endIdx),
        },
      ],
    }))
  }, [dataSource, currentChart]) // eslint-disable-line

  const handleNextPage = React.useCallback(() => {
    const idx = currentChart + 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  const handlePrevPage = React.useCallback(() => {
    const idx = currentChart - 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    setStartIdx(0)
    setCurrentChart(0)
  }, [query]) // eslint-disable-line

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  const { totalPages } = dataSource

  return (
    <>
      <Row gutter={[48, 48]} className="mr-40">
        <Col span={24}>
          <Pagination
            className="my-40 ml-[65px]"
            page={currentChart + 1}
            totalPages={totalPages}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
          />
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Box position="relative">
              <Box
                color="#fff"
                position="absolute"
                top="-35px"
                left="65px"
                zIndex={1}
                fontSize="18px"
                className="font-medium">
                %
              </Box>

              <HighchartsReact
                ref={chart}
                highcharts={Highcharts}
                containerProps={{ style: { width: '100%', height: '100%' } }}
                options={options}
              />

              {query.reportType !== TYPE.DAY ? (
                <Box
                  color="#fff"
                  position="absolute"
                  className="font-medium"
                  right="0px"
                  fontSize="14px">
                  {yAxisDateStr}
                </Box>
              ) : (
                t('report:days')
              )}
            </Box>
          </Spin>
        </Col>
        <ChartTitle {...props} />
      </Row>
    </>
  )
}

export default React.memo(LinkRateChart)
