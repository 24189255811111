import React from 'react'
import { compose, map, pathOr } from 'ramda'
import { convertData } from '@shared/innmaxLib/services'
import { convertMS } from '@shared/utils/webHelper'
import env from '@shared/env'
import { useConvertData } from '@services/device'
import { DeviceTypeId } from '@services/constants'
import { useAllSessions } from '@services/session'
import { useTranslation } from 'react-i18next'

// import { DeviceTypeId } from '@services/constants'
// import {
//   IElectricityMeter,
//   IElectricityMeterDetail,
// } from './electricitymeter.types'

export const useConvertChargingPoint = (res: any) => {
  const { byId } = useAllSessions()
  const { t } = useTranslation()

  const {
    deviceStatus,
    activeStr,
    sessionName,
    expiredDate,
    installTime,
    updateTime,
    locationNs,
  } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<any>({
        converter: x => {
          return {
            ...x,
            ...deviceStatus(x),
            ...activeStr(x),
            ...sessionName(x),
            ...expiredDate(x),
            ...installTime(x),
            ...updateTime(x),
            ...locationNs(x),
            sessionName: pathOr(t('report:no area assigned'), [x.sessionId, 'name'], byId),
            properties: {
              ...x.properties,
            },
          }
        },
      })(res),
    [res]
  )

  return dataSource
}


export const useConvertChargingPointDetail = (x?: any) => {
  const { byId } = useAllSessions()
  const { t } = useTranslation()

    const {
      deviceStatus,
      activeStr,
      sessionName,
      expiredDate,
      installTime,
      updateTime,
      lastOnlineAt,
      expiredAt,
      installAt,
      switchLeakageStr,
      locationNs,
      sessionPath,
      businessDurationTime,
      lastUseTime
    } = useConvertData()
  
    return React.useMemo(() => {
      if (!x) {
        return {}
      }
  
      return {
        ...x,
        ...deviceStatus(x),
        ...activeStr(x),
        ...sessionName(x),
        ...expiredDate(x),
        ...installTime(x),
        ...updateTime(x),
        ...locationNs(x),
        ...sessionPath(x),
        ...lastUseTime(x),
        ...businessDurationTime(x),
        deviceTypeId: DeviceTypeId[x.deviceType],
        sessionName: pathOr(t('report:no area assigned'), [x.sessionId, 'name'], byId),
  
        // lampCount: lamps.length,
        properties: {
          ...x.properties,
          ...switchLeakageStr(x),
        //   lamps: lamps.map((i: ILamp) => {
        //     return {
        //       ...i,
        //       ...deviceStatus(i),
        //       ...activeStr(i),
        //       ...lastOnlineAt(i),
        //       ...expiredAt(i),
        //       ...installAt(i),
        //       deviceId: x.deviceId,
        //       extra: {
        //         ...i.extra,
        //         time: convertMS(i.extra.usedSec),
        //       },
        //       properties: {
        //         ...i.properties,
        //         imgs: compose(
        //           map((y: IPhotoDto) => ({
        //             ...y,
        //             url: `${env.uploadFileUrlPrefix}${y.filePath}`,
        //           }))
        //         )(i.properties.imgs),
        //       },
        //     }
        //   }),
        },
      }
    }, [x])
  }