import InternalLeafletMap from './Leaflet'
import { LeafletProvider, useLeaflet } from './LeafletProvider'
import Marker from './Marker'
import AreaMarker from './AreaMarker'
import Tooltip from './Tooltip'
import FilterControl from './FilterControl'
import LayerControl from './LayerControl'
import ZoomControl from './ZoomControl'
import LayerToogle from './LayerToogle'

type InternalLeafletMapType = typeof InternalLeafletMap

interface LeafletMapInterface extends InternalLeafletMapType {
  Provider: typeof LeafletProvider
  Marker: typeof Marker
  AreaMarker: typeof AreaMarker
  useLeafletMap: typeof useLeaflet
  Tooltip: typeof Tooltip
  FilterControl: typeof FilterControl
  LayerControl: typeof LayerControl
  ZoomControl: typeof ZoomControl
  LayerToogle: typeof LayerToogle
}

const LeafletMap = InternalLeafletMap as LeafletMapInterface

LeafletMap.Provider = LeafletProvider
LeafletMap.Marker = Marker
LeafletMap.AreaMarker = AreaMarker
LeafletMap.useLeafletMap = useLeaflet
LeafletMap.Tooltip = Tooltip
LeafletMap.FilterControl = FilterControl
LeafletMap.LayerControl = LayerControl
LeafletMap.ZoomControl = ZoomControl
LeafletMap.LayerToogle = LayerToogle

export default LeafletMap
