import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { SelectValue, SelectProps as AntSelectProps } from 'antd/lib/select'

interface StyledSelectProps<T = SelectValue> extends AntSelectProps<T> {
  forwardRef?: React.Ref<any>
  ref?: any
}

export interface SelectProps<T = SelectValue> extends StyledSelectProps<T> {
  render?: (values: any) => any
  useForm?: boolean
}

const StyledSelect: any = styled(Select)`
  &.ant-select {
    & .ant-select-selection-item {
      color: ${p => p.theme.input.color};
    }
    & .ant-select-clear {
      background-color: transparent;
      & .anticon-close-circle {
        color: ${p => p.theme.input.color};
      }
    }

    .ant-select-selector {
      border-radius: 4px;
      border-color: ${p => p.theme.input.borderColor};
      background-color: ${p => p.theme.input.bg};

      .ant-select-selection-placeholder {
        color: ${p => p.theme.input.placeholderColor};
      }
    }

    .ant-select-arrow {
      color: ${p => p.theme.input.color};
    }

    .ant-select-selector:hover,
    .ant-select-selector:active,
    .ant-select-selector:focus {
      border-color: ${p => p.theme.primary};
    }

    .ant-select-selector:active,
    .ant-select-selector:focus {
      box-shadow: none;
    }

    .ant-select-arrow-icon {
      position: relative;
      top: -1px;
      font-size: 14px;
    }

    &.selection__multiple-items--hide
      .ant-select-selection--multiple
      .ant-select-selection__choice__disabled {
      display: none;
    }

    &.selection__schedule-items {
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: ${p => p.theme.info};
        color: #fff !important;
      }
      .ant-select-item {
      }
    }
  }

  &.ant-select-multiple {
    & .ant-select-selection-item {
      border: 1px solid #e9e9e9;
      background: #f7f7f7;
      color: #4a4a4a !important;
    }

    & .anticon-close > svg {
      /* fill: #fff; */
    }
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  &.ant-select-multiple.ant-select-disabled.ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #5c6365;
    opacity: 0.15;
    border: none;

    span {
      color: #8e8e8e;
      opacity: 0.1;
    }
  }
  
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-arrow {
    color: #8e8e8e;
    opacity: 0.5;
  }
`

export default class SelectComponent<T = any> extends React.Component<
  StyledSelectProps<T>,
  any
> {
  public static Option = Select.Option

  public static OptionGroup = Select.OptGroup

  public static displayName = 'Select'

  render() {
    const { forwardRef, ...others } = this.props
    return (
      <StyledSelect ref={forwardRef} {...others}>
        {this.props.children}
      </StyledSelect>
    )
  }
}

export { SelectComponent as Select }
