import type { TablePaginationConfig } from 'antd/lib/table'
import { mergeDeepRight } from 'ramda'
import Table from './Table'
import { TableChangeHandler } from '@shared/innmaxLib/hooks/useSearch'
import React from 'react'
import { TableProps } from './table.type'

export default function QueryTable<T = any>({
  rowKey = 'id',
  pagination = {},
  dataSource,
  onChange,
  ...props
}: TableProps<T>) {
  let paginationConfig = mergeDeepRight<any, any>(
    {
      pageSize: dataSource?.pageSize ?? 10,
      current: dataSource?.page ?? 1, //[fixed]: antd[Table] current should be positive  number
      total: dataSource?.total ?? 0,
    },
    pagination
  ) as TablePaginationConfig

  if (pagination === false) {
    pagination = false
  }

  React.useEffect(() => {
    if (!pagination) {
      return
    }
    const { total = 1, pageSize = 1, current = 1 } = pagination
    const lastPage = Math.max(1, Math.ceil(total / pageSize))
    if (current > lastPage) {
      onChange &&
        onChange(
          { current: lastPage, pageSize },
          {},
          {},
          {
            currentDataSource: [],
            action: 'paginate',
          }
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const handleChange: TableChangeHandler<T> = (...args) => {
    onChange && onChange(...args)
  }

  return (
    <Table
      rowKey={rowKey}
      dataSource={dataSource?.content}
      pagination={paginationConfig}
      onChange={handleChange}
      {...props}
    />
  )
}
