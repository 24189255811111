import { Col, Row, Space, Spin } from 'antd'
import React from 'react'
import Pagination from './Pagination'
import { css } from 'styled-components'
import { PAGE_SIZE } from '@services/report'

type IChartLayoutProps = React.PropsWithChildren<{
  yAxisTitle?: string
  chartTitle?: {
    title?: string
    subTitle?: string
  }
  loading?: boolean
  pagination?:
    | boolean
    | {
        page?: number
        totalPages?: number
        onNextPage?: () => void
        onPrevPage?: () => void
        startIndex?: number
        currentPageIndex?: number
        onStartIndexChange?: (idx: number) => void
        onCurrentPageIndexChange?: (page: number) => void
        totalSize?: number
      }
}>

const ChartLayout = (props: IChartLayoutProps) => {
  const { yAxisTitle, chartTitle, children, loading, pagination } = props

  const handleNextPage = React.useCallback(() => {
    if (typeof pagination !== 'object') {
      return
    }
    const {
      currentPageIndex = 0,
      onCurrentPageIndexChange,
      onStartIndexChange,
    } = pagination
    const idx = currentPageIndex + 1

    onStartIndexChange && onStartIndexChange(PAGE_SIZE * idx)
    onCurrentPageIndexChange && onCurrentPageIndexChange(idx)
  }, [pagination])

  const handlePrevPage = React.useCallback(() => {
    if (typeof pagination !== 'object') {
      return
    }
    const {
      currentPageIndex = 0,
      onCurrentPageIndexChange,
      onStartIndexChange,
    } = pagination

    const idx = currentPageIndex - 1

    onStartIndexChange && onStartIndexChange(PAGE_SIZE * idx)
    onCurrentPageIndexChange && onCurrentPageIndexChange(idx)
  }, [pagination])

  const totalPages =
    typeof pagination === 'object'
      ? Math.ceil((pagination.totalSize || 1) / PAGE_SIZE)
      : 0

  return (
    <Row gutter={[48, 48]} className="mr-40">
      {typeof pagination === 'object' && (
        <Col span={24}>
          <Pagination
            className="my-40 ml-[65px]"
            page={(pagination.currentPageIndex || 0) + 1}
            totalPages={totalPages}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
          />
        </Col>
      )}
      <Col span={24}>
        <Spin spinning={loading}>
          <div
            css={css`
              display: flex;
              width: 100%;
            `}>
            <div
              css={css`
                color: #fff;
                font-size: 12px;
                writing-mode: vertical-rl;
                text-orientation: mixed;
                margin-right: 5px;
                display: flex;
                justify-content: center;
                width: 20px;
              `}>
              {yAxisTitle}
            </div>
            <div
              css={css`
                width: calc(100% - 20px);
              `}>
              {children}
            </div>
          </div>
          <div className="text-center mb-5">
            <Space>
              <span className="text-primaryYellow mr-5">
                {chartTitle?.title}
              </span>
              <span className="text-white">{chartTitle?.subTitle}</span>
            </Space>
          </div>
        </Spin>
      </Col>
    </Row>
  )
}

export default ChartLayout
