import React from 'react'
import { Form, FormItemProps } from 'antd'
import styled from 'styled-components'

class FormItem extends React.Component<FormItemProps, any> {
  public static Button: typeof FIButtonComponent

  public render() {
    return (
      <StyleFormItem labelCol={{ span: 24 }} {...this.props}>
        {this.props.children}
      </StyleFormItem>
    )
  }
}

const FIButtonComponent = (props: FormItemProps) => (
  <FormItem label={<span />} {...props}>
    {props.children}
  </FormItem>
)

const StyleFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0 0 4px;

    label {
      font-size: 0.75rem !important;
      height: auto;
      color: ${p => p.theme.form.label};
    }
  }

  @media screen and (max-width: 768px) {
    .ant-form-item-label {
      label {
        font-size: 1rem !important;
      }
    }

    .ant-picker {
      width: 100% !important;
    }

    width: 100% !important;
    margin-bottom: 16px !important;
  }

  @media screen and (max-width: 500px) {
    .ant-btn {
      width: 100% !important;
      margin-bottom: 16px !important;
    }
  }
`

FormItem.Button = FIButtonComponent
export default FormItem
