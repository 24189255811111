import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, Layout } from 'antd'
import type { MenuProps } from 'antd'
import { is, pathOr, takeLast, find, propEq, concat } from 'ramda'
import styled, { css } from 'styled-components'
import * as Icons from '@shared/icons'
import { useProfile } from '@services/auth/useProfile'
import useViewport from '@shared/innmaxLib/hooks/useViewport'
import { useRoutes, RouteItem } from '@shared/innmaxLib/routes'
import * as routes from 'src/pages'
import { ApartmentOutlined } from '@ant-design/icons'
import envStore from '@shared/env'

const { has } = require('ramda')

const style = { height: '100%', borderRight: 0 }

function SideMenu() {
  const { t } = useTranslation()

  const location = useLocation()

  const { isMobile } = useViewport()

  const { _routes } = useRoutes(routes)

  const { profile } = useProfile()

  const [openKeys, setOpenKeys] = React.useState([])

  const currentRoute = React.useCallback(() => {
    const pathName =
      location.pathname === '/' ? '/dashboard' : location.pathname
    const isMatch = (regex: string) => pathName.match(new RegExp(regex, 'gi'))
    const items = _routes.map((route: any) =>
      route.isHideOnMenu
        ? isMatch(route.pathNoParams) &&
          _routes
            .filter((r: any) => r.code === route.parentCode)
            .map((i: any) => ({
              ...i,
              parentId: i.id,
            }))
        : !route.isSubMenu
        ? isMatch(route.path)
          ? [
              {
                ...route,
                parentId: route.id,
              },
            ]
          : []
        : route.children
            .filter((c: any) => isMatch(c.path))
            .map((i: any) => ({
              ...i,
              parentId: route.id,
            }))
    )
    return pathOr(
      [],
      [0, 0],
      items.filter((i: any) => i?.length)
    )
  }, [_routes]) // eslint-disable-line

  const currentRouteKey: any = currentRoute()

  const handleOpenChange = (openKeys: React.Key[]) => {
    setOpenKeys(takeLast(1, openKeys) as any) // ramda對ts的支援似乎還不夠好...型別會錯
  }

  const renderMenuIcon = (item: RouteItem) => {
    let Icon: any = item.icon

    if (is(String, Icon) && has([Icon])(Icons)) {
      const Component = (Icons as any)[Icon]
      Icon = <Component />
    }

    if (is(Function, Icon)) {
      Icon = <Icon />
    }

    return Icon
  }

  const items: MenuProps['items'] = React.useMemo(() => {
    return _routes.map((route: any) => {
      if (
        route.isHideOnMenu ||
        (route.isSub && !find(propEq('readable', true))(route.children)) ||
        (!route.isSub && !route.readable)
      ) {
        return null
      }

      return {
        label: !route.isSubMenu ? (
          <Link
            to={route.path}
            data-title={
              route.mainKey ? t(`menu:${route.mainKey}`) : route.title
            }>
            {route.mainKey ? t(`menu:${route.mainKey}`) : route.title}
          </Link>
        ) : (
          <div
            data-title={
              route.mainKey ? t(`menu:${route.mainKey}`) : route.title
            }>
            {route.mainKey ? t(`menu:${route.mainKey}`) : route.title}
          </div>
        ),
        key: route.id,
        icon: renderMenuIcon(route),
        children:
          route.children.length &&
          route.children.map((children: any) => {
            if (!children.readable || !children.isHideOnMenu) {
              return null
            }

            return {
              label: (
                <Link
                  to={children.path}
                  data-title={t(`menu:${children.path}`)}>
                  {t(`menu:${children.path}`)}
                </Link>
              ),
              key: children.id,
            }
          }),
      }
    })
  }, [_routes]) //eslint-disable-line

  const subSystemLinks = React.useMemo(() => {
    return [
      {
        label: <div data-title={'子系統連結'}>子系統連結</div>,
        key: 'subSystemLinks',
        icon: <Icons.IconSubSystem />,
        children: [
          {
            title: 'JRSCADA智慧監控',
            url: '/',
          },
          { title: 'Genetec 智慧安防', url: '/' },
          { title: '智慧路燈', url: envStore.lightingSubSystemUrl },
          { title: '智慧水電錶', url: envStore.metersSubSystemUrl },
        ].map(subSystem => ({
          label: (
            <a
              data-title={subSystem.title}
              href={subSystem.url}
              target="_blank"
              rel="noopener noreferrer">
              {subSystem.title}
            </a>
          ),
          key: subSystem.title,
        })),
      },
    ]
  }, [])

  return (
    <StyledLayoutSider collapsed={isMobile} width={160}>
      <div className="m-16">
        <Brand to="/" data-title={profile.name}>
          <img src={profile.url} alt={profile.name} />
          <div>{profile.name}</div>
        </Brand>
      </div>
      <StyledMenu
        mode="inline"
        selectedKeys={currentRouteKey?.id?.toString()}
        style={style}
        items={items || []}
        // items={concat(items || [], subSystemLinks)}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
      />
    </StyledLayoutSider>
  )
}

const StyledLayoutSider = styled(Layout.Sider)`
  background: #1b1d1d;
`

const StyledMenu = styled(Menu)`
  background: ${p => p.theme.menu.bg};

  .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-sub.ant-menu-inline {
    background: ${p => p.theme.menu.bg};
  }

  .ant-menu-submenu {
    margin-bottom: 5px;
  }

  .ant-menu-item,
  .ant-menu-submenu .ant-menu-submenu-title {
    padding: 10px !important;

    &.ant-menu-item-only-child {
      padding-left: 39px !important;
    }

    .ant-menu-title-content,
    .ant-menu-title-content > a {
      font-size: 14px;
      font-weight: normal;
      color: ${p => p.theme.menu.color};
    }

    i.ant-menu-submenu-arrow {
      color: ${p => p.theme.menu.color};
    }

    i[class*='ant-menu-item-icon'] > svg > g,
    i[class*='ant-menu-item-icon'] > svg > g > g,
    i[class*='ant-menu-item-icon'] > svg > g > path {
      stroke: ${p => p.theme.menu.color};
    }

    i[class*='ant-menu-item-icon'] > svg > path {
      fill: ${p => p.theme.menu.color};
    }

    &:hover {
      background: ${p => p.theme.menu.hoverColorBg};
      .ant-menu-title-content,
      .ant-menu-title-content > a {
        color: ${p => p.theme.menu.hoverColorText};
      }

      i[class*='ant-menu-item-icon'] > svg > g,
      i[class*='ant-menu-item-icon'] > svg > g > g,
      i[class*='ant-menu-item-icon'] > svg > g > path,
      i[class*='ant-menu-item-icon'] > svg > g > circle {
        stroke: ${p => p.theme.menu.hoverColorText};
      }

      i[class*='ant-menu-item-icon'] > svg > path {
        fill: ${p => p.theme.menu.hoverColorText};
      }

      i.ant-menu-submenu-arrow {
        color: ${p => p.theme.menu.hoverColorText} !important;
      }
    }
  }

  .ant-menu-submenu-selected,
  .ant-menu-item.ant-menu-item-selected {
    > .ant-menu-submenu-title > .ant-menu-title-content,
    > .ant-menu-submenu-title > .ant-menu-title-content > a,
    .ant-menu-item-selected.ant-menu-item-only-child a,
    :not(.ant-menu-submenu) a {
      color: ${p => p.theme.menu.hoverColor} !important;
    }

    i[class*='ant-menu-item-icon'] > svg > g,
    i[class*='ant-menu-item-icon'] > svg > g > g,
    i[class*='ant-menu-item-icon'] > svg > g > path {
      stroke: ${p => p.theme.menu.hoverColor} !important;
    }

    i[class*='ant-menu-item-icon'] > svg > path {
      fill: ${p => p.theme.menu.hoverColor} !important;
    }

    i.ant-menu-submenu-arrow {
      color: ${p => p.theme.menu.hoverColor} !important;
    }
  }

  .ant-menu-submenu-selected {
    background: ${p => p.theme.menu.activeBg};

    > .ant-menu-submenu-title {
      border-left: 2px solid ${p => p.theme.menu.activeText};
    }

    > .ant-menu-submenu-title > .ant-menu-title-content,
    > .ant-menu-submenu-title > .ant-menu-title-content > a,
    :not(.ant-menu-submenu) a {
      color: ${p => p.theme.menu.activeText} !important;
    }

    i[class*='ant-menu-item-icon'] > svg > g,
    i[class*='ant-menu-item-icon'] > svg > g > g,
    i[class*='ant-menu-item-icon'] > svg > g > path,
    i[class*='ant-menu-item-icon'] > svg > g > circle {
      stroke: ${p => p.theme.menu.activeText} !important;
    }

    i[class*='ant-menu-item-icon'] > svg > path {
      fill: ${p => p.theme.menu.activeText} !important;
    }

    i.ant-menu-submenu-arrow {
      color: ${p => p.theme.menu.activeText} !important;
    }
  }

  .ant-menu-item.ant-menu-item-selected {
    background: ${p => p.theme.menu.subActiveBg};
  }
`

const Brand = styled(Link)`
  // width: 100%;
  // margin-bottom: 15px;
  // background-color: red;

  > img {
    width: 100%;
    height: auto;
    max-width: 127px;
    max-height: 60px;
    margin: 0 auto;
  }

  > div {
    min-height: 8px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: ${p => p.theme.logoText} !important;
  }
`

export default SideMenu
