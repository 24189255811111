import React from 'react'
import { usePaymentType } from '@services/device'
import { Select, SelectProps } from '@shared/Form/Select'
import { useTranslation } from 'react-i18next'

export const PaymentTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  (props, ref): any => {
    const { t } = useTranslation()

    const { dataSource, isLoading } = usePaymentType()
    const _dataSource: any = dataSource

    // console.log('dataSource',dataSource);
    
    return (
      <Select allowClear placeholder={t('device:select payment')} {...props}>
        {(_dataSource?.data || []).map((x: any) => (
          <Select.Option key={x} value={x}>
            {x}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export default PaymentTypeSelect
