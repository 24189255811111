import env from '@shared/env'

export enum AllDeviceType {
  LIGHT = 'streetlight', //燈桿
  LAMP_CTLER = 'lampCtler', //燈桿上的燈泡
  BOX = 'switchbox',
  TALKIE = 'visualdoorbell',
  CAMERA = 'camera',
  WDETECT = 'waterlevel',
  LOCATION = 'location',
  ATTACHED = 'attached',
  DISPLAYSCREEN = 'displayscreen',
  WEATHERSTATION = 'weatherstation',
  POWERSUPPLIER = 'powersupplier',
  CHARGINGPOINT = 'chargingpoint',
  AIBOX = 'aibox',
  ELECTRICMETER = 'electricitymeter',
  WATERMETER = 'watermeter',
}

export enum DeviceType {
  LIGHT = 'streetlight', //燈桿
  LAMP_CTLER = 'lampCtler', //燈桿上的燈泡
  BOX = 'switchbox',
  TALKIE = 'visualdoorbell',
  CAMERA = 'camera',
  WDETECT = 'waterlevel',
  LOCATION = 'location',
  ATTACHED = 'attached',
  DISPLAYSCREEN = 'displayscreen',
  WEATHERSTATION = 'weatherstation',
  POWERSUPPLIER = 'powersupplier',
  CHARGINGPOINT = 'chargingpoint',
  AIBOX = 'aibox',
  AIR_CONDITIONER = 'airconditioner',
  ELECTRIC_METER = 'electricitymeter',
  WATER_METER = 'watermeter',
  SENSOR = 'sensor',
}

export enum SupportedDeviceType {}
env.deviceTypeSupported.map(
  (type: any) =>
    ((SupportedDeviceType as any)[type] = (AllDeviceType as any)[type])
)

export enum CameraType {
  UNKNOWN = 0,
  BULLET = 1,
  BULLET_OVERALL = 2,
  DOME_OVERALL = 3,
}

export enum MediaType {
  UNKNOWN = 0,
  MJPG = 'MJPEG',
  JPG = 2,
  FLV = 'FLV',
  MP4 = 4,
  REPLAY = 5, //錄影回放
  IFRAME = 6,
  HTML = 'HTML',
  RTSP = 'RTSP',
}

export const DeviceTypeId: { [key: number | string]: DeviceType | number } = {
  0: DeviceType.LIGHT, //燈桿
  1: DeviceType.LIGHT, //燈桿上的燈泡
  2: DeviceType.BOX,
  3: DeviceType.TALKIE,
  4: DeviceType.CAMERA,
  5: DeviceType.WDETECT,
  6: DeviceType.LOCATION,
  7: DeviceType.ATTACHED,
  8: DeviceType.DISPLAYSCREEN,
  9: DeviceType.WEATHERSTATION,
  10: DeviceType.POWERSUPPLIER,
  11: DeviceType.AIBOX,
  12: DeviceType.ELECTRIC_METER,
  13: DeviceType.WATER_METER,
  [DeviceType.LIGHT]: 0,
  [DeviceType.LAMP_CTLER]: 1,
  [DeviceType.BOX]: 2,
  [DeviceType.TALKIE]: 3,
  [DeviceType.CAMERA]: 4,
  [DeviceType.DISPLAYSCREEN]: 8,
  [DeviceType.WEATHERSTATION]: 9,
  [DeviceType.POWERSUPPLIER]: 10,
  [DeviceType.ELECTRIC_METER]: 12,
  [DeviceType.WATER_METER]: 13,
}

export enum DeviceStatus {
  NORMAL = 0,
  ALARM = 1,
  REPAIR = 2,
}

export const DeviceStatusStr: { [key: string | number]: string } = {
  [DeviceStatus.NORMAL]: 'normal',
  [DeviceStatus.ALARM]: 'alarm',
  [DeviceStatus.REPAIR]: 'repair',
}

export enum DeviceActive {
  DELETE = -1,
  INACTIVE = 0,
  ACTIVE = 1,
}

export const DeviceActiveStr: { [key: string | number]: string } = {
  [DeviceActive.DELETE]: 'delete',
  [DeviceActive.INACTIVE]: 'inactive',
  [DeviceActive.ACTIVE]: 'active',
}

export const DeviceMarkerColor: {
  [key: string]: {
    icon: string
    normal: string
    repair: string
    alarm: string
    low?: string
    off?: string
    selected: string
  }
} = {
  [DeviceType.LIGHT]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.LIGHT}/normal.svg`,
    normal: 'linear-gradient(315deg, #FFCC2B, #FFCC2B)',
    low: 'linear-gradient(315deg, #a32000, #ffc91b)',
    off: 'linear-gradient(315deg, #967305, #000)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #FFCC2B, #FFCC2B)',
  },
  [DeviceType.CAMERA]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.CAMERA}/normal.svg`,
    normal: 'linear-gradient(315deg, #006f34, #a6ff39)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.TALKIE]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.TALKIE}/normal.svg`,
    normal: 'linear-gradient(315deg, #8e4700, #ee8400)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.BOX]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.BOX}/normal.svg`,
    normal: 'linear-gradient(315deg, #5200a0, #cd4cbc)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.WDETECT]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.WDETECT}/normal.svg`,
    normal: 'linear-gradient(315deg, #12729e, #00a0e9, #7deeff)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.DISPLAYSCREEN]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.DISPLAYSCREEN}/normal.svg`,
    normal: 'linear-gradient(315deg, #6a5e00, #b4b370)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.WEATHERSTATION]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.WEATHERSTATION}/normal.svg`,
    normal: 'linear-gradient(315deg, #12229e, #7da5ff)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.POWERSUPPLIER]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.POWERSUPPLIER}/normal.svg`,
    normal: 'linear-gradient(315deg, #00a089, #96ffc8)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  pierlight: {
    icon: `${env.imageUrlPrefix}/marker/pierlight/normal.svg`,
    normal: 'linear-gradient(315deg, #FFC875, #FFC875)',
    low: 'linear-gradient(315deg, #a32000, #ffc91b)',
    off: 'linear-gradient(315deg, #967305, #000)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(315deg, #FFC875, #FFC875)',
  },
  watermeter: {
    icon: `${env.imageUrlPrefix}/marker/watermeter/normal.svg`,
    normal: 'linear-gradient(315deg, #00AED4, #00AED4)',
    low: 'linear-gradient(315deg, #a32000, #ffc91b)',
    off: 'linear-gradient(315deg, #967305, #000)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(315deg, #00AED4, #00AED4)',
  },
  electricitymeter: {
    icon: `${env.imageUrlPrefix}/marker/electricitymeter/normal.svg`,
    normal: 'linear-gradient(315deg, #03A000, #03A000)',
    low: 'linear-gradient(315deg, #a32000, #ffc91b)',
    off: 'linear-gradient(315deg, #967305, #000)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(315deg, #03A000, #03A000)',
  },
}
