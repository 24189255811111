import { useMutation } from 'react-query'
import Store from 'store'
import { request } from '@shared/innmaxLib/services'
import message from '@shared/innmaxUI/Message'
import type { MenuAuth, User, UserPermission } from './auth.types'
import { QueryVariables } from '@shared/innmaxLib/services'
import { useMineInfo } from './useMineInfo'
import { useHistory } from 'react-router-dom'
import StorageKeys from '@shared/storageKeys'

const convertData = (auth: MenuAuth) => {
  return {
    id: auth.id,
    name: auth.subName,
    url: auth.webPath,
    icon: auth.icon,
    code: auth.code,
    mainKey: auth.mainKey,
    subKey: auth.subKey,
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    parentId: auth.id,
    parentName: auth.name,
  } as UserPermission
}

export const setUserAuth = (auth: MenuAuth, permission: UserPermission) => {
  if (permission) {
    const child = convertData(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }

  return {
    ...auth,
    url: auth.isSub ? auth.code : auth.webPath, //submenu need unique id
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    ...(auth.isSub
      ? {
          children: [
            { ...convertData(auth), parentId: auth.id, parentName: auth.name },
          ],
        }
      : { children: [] }),
  } as UserPermission
}

export const loginAPI = (body: User) => {
  return request('/login', {
    body,
    method: 'POST',
    displayMessage: false,
  })
}

export const useLogin = (options: QueryVariables = {}) => {
  const history = useHistory()

  const { refetch: getMineInfo, isLoading: loadingMineInfo } = useMineInfo({
    onSuccess: () => {
      history.push('/dashboard')
      // history.push('/system-entrance')
    },
  })

  const { mutate, isLoading, ...rest } = useMutation(loginAPI, {
    onError: (error: Error) => {
      console.error(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      if (response?.errorMessage) {
        message({ content: response?.errorMessage, type: 'error' })
        return
      }

      Store.set(StorageKeys.token, response.token)

      getMineInfo()
    },
  })

  return {
    ...rest,
    mutate,
    loading: isLoading || loadingMineInfo,
    login: mutate,
  }
}

export default useLogin
