import React from 'react'
import { useQuery } from 'react-query'
import { QueryVariables } from '@shared/innmaxLib/services'
import { isNil } from '@shared/utils/webHelper'
import { useConvertChargingPayment } from './charging-payment.converter'
import {
  IQueryProps,
  PAGE_SIZE,
  TYPE,
  xAxisName,
  getLinkRateAverageData,
} from '@services/report'

export const useChargingPayment = (
  query: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useQuery(
    [
      '/device/chargingpoint/report/chargingPayment/{pageNum}/{pageSize}',
      query,
    ],
    {
      enabled: !isNil(query),
      cacheTime: 0,
      ...options,
    }
  )

  const { dataSource } = useConvertChargingPayment(data)

  return {
    dataSource,
    ...others,
  }
}

export const useExportChargingPayment = (
  query?: IQueryProps,
  options?: QueryVariables
) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    [
      '/device/chargingpoint/report/chargingPayment/export',
      { ...query, pageNum: undefined, pageSize: undefined },
    ],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
