import { useQuery, UseQueryOptions } from 'react-query'
import { useConvertWaterMeters } from './watermeter.converter'
import { path, reverse } from 'ramda'

export const useWaterMeters = (query?: any, options?: UseQueryOptions) => {
  const { data, ...others } = useQuery(
    ['/device/watermeter/{pageNum}/{pageSize}', query],
    {
      cacheTime: 0,
      ...options,
    }
  )

  const dataSource = useConvertWaterMeters(data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

//控制器型號
export const useWaterMetersControllerType = () => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/device/watermeter/ctrler/types`],
    {
      select: (res: any) => path(['data'], res) as { ctrlerType: string }[],
    }
  )

  return {
    ...others,
    refetch,
    loading: isLoading,
    data,
  }
}

export const useWaterMeterSignalLog = (query?: {
  id: any
  start: number
  end: number
}) => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/device/watermeter/{id}/signalLog`, query],
    {
      select: (res: any) => path(['data'], res) as any[],
      cacheTime: 0,
    }
  )

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: reverse(data || []),
  }
}
