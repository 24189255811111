import { useQuery } from 'react-query'
import { useAuth } from '.'
import type { MenuAuth, UserPermission } from './auth.types'
import type { IUser } from '../user'
import { setUserAuth } from './useLogin'
import { forEach, pathOr, compose } from 'ramda'
import Store from 'store'
import { QueryVariables } from '@shared/innmaxLib/services'
import StorageKeys from '@shared/storageKeys'

export const useMineInfo = (options: QueryVariables = {}) => {
  const { setIsAuthenticated, setUser } = useAuth()

  const { refetch, isLoading, data } = useQuery(['/account/mineInfo'], {
    cacheTime: Infinity,
    refetchOnMount: false,
    enabled: false,
    onError: error => {
      console.log(error)
      options?.onError && options.onError(error)
      setIsAuthenticated(false)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      compose(
        forEach((x: MenuAuth) => {
          authById[x.name] = setUserAuth(x, authById[x.name])
          authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])
        }),
        pathOr([], ['data', 'roleObj', 'authDetails'])
      )(response)

      const funcList = Object.keys(authById).map(i => authById[i])

      const user = {
        ...response?.data,
        authById,
        authByUrl,
        funcList,
      }

      Store.set(StorageKeys.user, user)

      setIsAuthenticated(true)

      setUser(user)

      options?.onSuccess && options.onSuccess(response?.data)
    },
  })

  return {
    refetch,
    isLoading,
    dataSource: pathOr<IUser | undefined>(undefined, ['data'], data),
  }
}
