import styled from 'styled-components'
import { Input } from 'antd'

const Search = styled(Input.Search)`
  &.ant-input-search {
    background-color: ${p => p.theme.input.bg};
    border-color: transparent;
    .ant-input {
      border-radius: 4px;
      background-color: ${p => p.theme.input.bg};
      color: ${p => p.theme.input.color};
      border-color: transparent;
      ::placeholder {
        color: ${p => p.theme.input.placeholderColor};
      }

      &:hover,
      &:focus {
        border-color: ${p => p.theme.primary};
      }

      &:focus {
        box-shadow: none;
      }
    }
    .ant-input-group-addon,
    .ant-btn {
      border: 0px;
      background: transparent;
    }
    .anticon-search {
      font-size: 20px;
      color: #fff;
    }
  }
`

export default Search
