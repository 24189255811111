import React from 'react'
import { pathOr, length } from 'ramda'
import styled, { css } from 'styled-components'
import cx from 'classnames'
import ReactDOMServer from 'react-dom/server'
import L, {
  point,
  LatLngExpression,
  divIcon,
  LeafletMouseEvent,
  LeafletEvent,
  PointExpression,
} from 'leaflet'
import { Popup, Marker as MarkerComponent } from 'react-leaflet'
import theme from '@theme'
import { DeviceStatus } from '@services/constants'
import { ISession, useAllSessions } from '@services/session'
import { Device } from '@services/device'
import { imageUrlPrefix } from '@shared/env'
import Tooltip from './Tooltip'
import { Badge } from '@shared/innmaxUI'
import { toThousandSeparator } from '@shared/innmaxLib/utils/webHelper'
import useVisible from '@shared/innmaxLib/hooks/useVisible'
import { DeleteWindow } from '../../components/Window'
import { useMap } from 'react-leaflet'
import { MapType } from '../map.types'

interface AreaMarkerComponentProps {
  item: ISession
  focus?: boolean
  tooltip?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean
  size?: 'small' | 'large'

  onLeftClick?: (d: ISession) => void
  onRightClick?: (d: ISession) => void
  onMouseOver?: (d: ISession) => void
  onMouseOut?: (d: ISession) => void
  onDrag?: (e: LeafletMouseEvent) => void
  onDragEnd?: (e: ISession) => void
  onDelete?: (d: ISession) => void
}

export default function AreaMarker({
  item,
  focus,
  tooltip = true,
  deletable = false,
  draggable = false,
  selected = false,
  size,

  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  onDrag,
  onDragEnd,
  onDelete,
  ...others
}: AreaMarkerComponentProps) {
  const markerRef = React.useRef<any>()

  const [markerPosition, setMarkerPosition] = React.useState<LatLngExpression>()

  const [zindex, setZindex] = React.useState(0)

  const tooltipVisible = useVisible()

  const deleteWindow = useVisible()

  const { byId } = useAllSessions()

  const status: DeviceStatus = React.useMemo(() => {
    return item?.devices?.filter(d => d.deviceStatus === DeviceStatus.ALARM)
      .length
      ? DeviceStatus.ALARM
      : item?.devices?.filter(d => d.deviceStatus === DeviceStatus.REPAIR)
          .length
      ? DeviceStatus.REPAIR
      : DeviceStatus.NORMAL
  }, [item])

  const iconImage = React.useMemo(() => {
    switch (status) {
      case DeviceStatus.ALARM:
        return `${imageUrlPrefix}/cluster/cluster-alarm${
          size === 'small' ? '-small' : ''
        }.svg`
      case DeviceStatus.REPAIR:
        return `${imageUrlPrefix}/cluster/cluster-repair${
          size === 'small' ? '-small' : ''
        }.svg`
      case DeviceStatus.NORMAL:
        return `${imageUrlPrefix}/cluster/cluster-normal${
          size === 'small' ? '-small' : ''
        }.svg`
    }
  }, [status, size])

  const map = useMap()

  const eventHandlers = React.useMemo(
    () => ({
      contextmenu() {
        deletable && deleteWindow.toggle()
        onRightClick && onRightClick(item)
      },
      click() {
        onLeftClick && onLeftClick(item)
      },
      mouseover() {
        onMouseOver && onMouseOver(item)
        tooltipVisible.open()
      },
      mouseout() {
        onMouseOut && onMouseOut(item)
        tooltipVisible.close()
      },
      drag(e: LeafletEvent) {
        setMarkerPosition((e as LeafletMouseEvent).latlng)
        onDrag && onDrag(e as LeafletMouseEvent)
        setZindex(new Date().getTime())
      },
      dragend(e: any) {
        setMarkerPosition(markerRef.current._latlng)
        onDragEnd &&
          onDragEnd({
            ...item,
            lat: markerRef.current._latlng.lat.toFixed(15),
            lon: markerRef.current._latlng.lng.toFixed(15),
            x: Math.round(map.project(markerRef.current._latlng, 0).x),
            y: Math.round(map.project(markerRef.current._latlng, 0).y),
          })
      },
    }),
    []
  ) //eslint-disable-line

  const icon = React.useMemo(() => {
    if (iconImage) {
      const iconSize: PointExpression =
        size === 'small' ? [108, 108] : [180, 180]

      return divIcon({
        iconSize: iconSize,
        iconAnchor: [iconSize[0] / 2, iconSize[1] / 2],
        popupAnchor: [120, 140],
        className: '',
        html: ReactDOMServer.renderToString(
          <StyledMarkerWrapper
            size={size}
            style={{ backgroundImage: `url(${iconImage})` }}>
            <div className="area truncate">{item.name}</div>
            {pathOr([], ['devices'], item) && (
              <>
                <div
                  className={cx(
                    'total',
                    DeviceStatus[status].toLocaleLowerCase()
                  )}>
                  {toThousandSeparator(pathOr([], ['devices'], item).length)}
                </div>
                <div className="status">
                  <Badge status={DeviceStatus.ALARM} dotsize={8} border />
                  <span>
                    {toThousandSeparator(
                      length(
                        pathOr<Device[]>([], ['devices'], item).filter(
                          d => d?.deviceStatus === DeviceStatus.ALARM
                        )
                      ) || 0
                    )}
                  </span>
                </div>
                <div className="status">
                  <Badge status={DeviceStatus.REPAIR} dotsize={8} border />
                  <span>
                    {toThousandSeparator(
                      length(
                        pathOr<Device[]>([], ['devices'], item).filter(
                          d => d.deviceStatus === DeviceStatus.REPAIR
                        )
                      )
                    )}
                  </span>
                </div>
              </>
            )}
          </StyledMarkerWrapper>
        ),
      })
    }
  }, [item, size]) //eslint-disable-line

  React.useEffect(() => {
    if (!map || !item) {
      return
    }
    if (
      byId[item.parentSessionId].type === MapType.MAP &&
      item.lat &&
      item.lon
    ) {
      setMarkerPosition({ lat: item.lat, lng: item.lon })
    }
    if (
      byId[item.parentSessionId].type === MapType.Upload &&
      item.x &&
      item.y
    ) {
      setMarkerPosition(map.unproject({ x: item?.x, y: item?.y } as any, 0))
    }
  }, [byId, item, map])

  if (!markerPosition) {
    return <div />
  }

  return (
    <>
      {deleteWindow.visible && (
        <StylePopup
          position={markerPosition}
          offset={size === 'small' ? [178, 70] : [238, 105]}
          eventHandlers={{
            remove: deleteWindow.toggle,
          }}>
          <DeleteWindow
            data={item}
            onCancel={deleteWindow.toggle}
            deleteMarker={() => onDelete && onDelete(item)}
          />
        </StylePopup>
      )}

      <MarkerComponent
        ref={markerRef}
        position={markerPosition}
        eventHandlers={eventHandlers}
        draggable={draggable}
        icon={icon}
        zIndexOffset={zindex}>
        {tooltipVisible.visible && (
          <Tooltip
            className="skip-toggle-dialog"
            permanent
            direction="top"
            offset={size === 'small' ? point(35, -32) : point(67, -35)}>
            {item?.name}
          </Tooltip>
        )}
      </MarkerComponent>
    </>
  )
}

const StyledMarkerWrapper = styled(({ ...props }) => <div {...props} />)`
  width: 180px;
  height: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;

  ${p =>
    p.size === 'small' &&
    css`
      width: 108px;
      height: 108px;
      padding-top: 8px;
      line-height: 17px;
    `};

  .area {
    width: 55%;
    border-radius: 13px;
    background-color: ${theme.light};
    color: ${theme.n.gray400};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 10px;

    ${p =>
      p.size === 'small' &&
      css`
        width: 60%;
        font-size: 12px;
        background-color: unset;
        margin-bottom: 2px;
      `};
  }

  .total {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    color: ${theme.light};

    &.alarm,
    &.repair {
      color: ${theme.n.yellow400};
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
        color: ${theme.light};
      `};
  }

  .status {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: ${theme.light};
    margin-bottom: 3px;

    span:first-child {
      margin-right: 2px;
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
      `};
  }
`

const StylePopup = styled(Popup)`
  .leaflet-popup-content {
    margin: 0px;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none;
  }
`
