import '@theme/index.css'
import '@public/locales/i18n'
import './bootstrap'

import React from 'react'
import { Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhTW from 'antd/lib/locale/zh_TW'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider } from 'styled-components'
import MapProvider from '@shared/innmaxMap/map/MapProvider'
import GoogleMap from '@shared/innmaxMap/map/GoogleMap'
import Leaflet from '@shared/innmaxMap/map/Leaflet'
import defaultTheme from '@theme'
import { AuthContext, AuthProvider } from '@services/auth'
import { createQueryClient } from '@shared/innmaxLib/services'
import { history } from '@shared/innmaxLib/routes'
import createPack from '@shared/innmaxLib/utils/renderer/createPack'
import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/de'
import envStore from '@shared/env'
import useCspUtils from '@shared/innmaxLib/hooks/useCspUtils'

import '@ant-design/flowchart/dist/index.css'

type Props = {
  theme?: { [key: string]: any }
}

const EnhancedApp = ({ theme = {}, children }: any) => {
  const [locale, setLocale] = React.useState(zhTW)

  useCspUtils()

  const Provider = createPack(
    <ThemeProvider theme={mergeDeepRight(defaultTheme, theme)} />,
    <RecoilRoot />,
    <MapProvider />,
    <GoogleMap.Provider />,
    <Leaflet.Provider />
  )

  React.useEffect(() => {
    i18n.on('languageChanged', function () {
      setLocale(i18n.language === 'zh-TW' ? zhTW : enUS)
      moment.locale(i18n.language === 'zh-TW' ? 'zh-tw' : 'en')
    })
  }, [])

  useEnhanceRemoveChild()

  return (
    <Provider>
      <AuthProvider>
        <AuthContext.Consumer>
          {auth => (
            <QueryClientProvider client={createQueryClient(auth)}>
              <ConfigProvider
                locale={locale}
                csp={{ nonce: envStore.cspNonce }}>
                <Router history={history}>{children}</Router>
              </ConfigProvider>
            </QueryClientProvider>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </Provider>
  )
}

const renderer =
  (props: Props = {}) =>
  (App: React.FunctionComponent) => {
    return (
      <EnhancedApp {...props}>
        <App />
      </EnhancedApp>
    )
  }

export default renderer

/**
 * 當 removeChild 的 child 非子節點時，回傳 null，避免拋出錯誤
 */
const useEnhanceRemoveChild = () => {
  React.useEffect(() => {
    const nativeRemove = Element.prototype.removeChild
    // @ts-ignore
    Element.prototype.removeChild = function (child) {
      if (this.contains(child)) {
        return nativeRemove.call(this, child)
      } else {
        return null
      }
    }
  }, [])
}
