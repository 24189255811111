import i18n from 'i18next'

export const activeEnums: any = {
  0: i18n.t('system:inactive'),
  1: i18n.t('system:active'),
}

export const YesNoEnums = {
  true: i18n.t('system:yes'),
  false: i18n.t('system:no'),
}

export const isPopUpEnums = {
  true: '嚴重警報',
  false: '一般警報',
}