import { Select, SelectProps } from '@shared/Form/Select'
import { SupportedDeviceType } from '@services/constants'
import { useTranslation } from 'react-i18next'

type DeviceSelectType = {
  showAll?: boolean
  others?: { value: string; name: string }[]
} & SelectProps

export default function DeviceSelect({
  showAll = false,
  others = [],
  ...props
}: DeviceSelectType) {
  const { t } = useTranslation()

  return (
    <Select allowClear placeholder={t('report:select device')} {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {Object.keys(SupportedDeviceType)
        .filter(
          d =>
            [
              'LOCATION',
              'ATTACHED',
              'LAMP_CTLER',
              'DISPLAYSCREEN',
              'WEATHERSTATION',
              'POWERSUPPLIER',
              'BOX',
            ].indexOf(d) === -1
        )
        .map(x => (
          <Select.Option key={x} value={(SupportedDeviceType as any)[x]}>
            {t(`device:select-${(SupportedDeviceType as any)[x]}`)}
          </Select.Option>
        ))}
      {others.map(other => (
        <Select.Option key={other.value} value={other.value}>
          {other.name}
        </Select.Option>
      ))}
      {/* <Select.Option value={'watermeter'}>水錶</Select.Option> */}
      {/* <Select.Option value={'electricitymeter'}>電錶</Select.Option> */}
      {/* <Select.Option value={'pierlight'}>碼頭裝卸照明</Select.Option> */}
    </Select>
  )
}
