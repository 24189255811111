import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { slice } from 'ramda'
import { ColumnProps } from 'antd/lib/table'
import { ReportTable } from '@shared/Table'

import { IPower } from '@services/report'
import ReportTitle from '../CommonTitle'
import type { ICommonProps } from '../type'

export const pageSize = 10
function PowerReport({
  queryVariables,
  dataSource,
  loading,
  ...props
}: ICommonProps) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const columns: ColumnProps<IPower>[] = [
    {
      dataIndex: 'displayDate',
      title: t('report:time'),
      align: 'center',
      width: 160,
    },
    {
      dataIndex: 'value',
      title: t('report:power consumption') + 'kWh',
      align: 'center',
      width: 220,
      render: v => {
        return <>{Math.round(v * 100) / 100}</>
      },
    },
  ]

  return (
    <Row className="mt-42">
      <ReportTitle total={dataSource.content.length} {...props} />
      <Col span={16}>
        <ReportTable
          rowKey="id"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
              dataSource.content
            ),
          }}
          loading={loading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize,
          }}
          onChange={(p: any) => setCurrentPage(p.current || 1)}
        />
      </Col>
    </Row>
  )
}

export default React.memo(PowerReport)
