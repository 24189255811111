import styled from 'styled-components'
import { Input } from 'antd'

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper {
    background-color: ${p => p.theme.input.bg};
    border-color: transparent;

    &:has(.ant-input) {
      .ant-input {
        background-color: transparent !important;
      }
    }

    & .ant-input-clear-icon {
      color: ${p => p.theme.input.color};
    }
  }
  &.ant-input-affix-wrapper .ant-input,
  &.ant-input {
    border-radius: 4px;
    background-color: ${p => p.theme.input.bg};
    color: ${p => p.theme.input.color};
    border-color: transparent;

    ::placeholder {
      color: ${p => p.theme.input.placeholderColor};
    }

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primary};
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.ant-input-group-wrapper {
    &.custom-input-addon .ant-input-group-addon {
      background: ${p => p.theme.whisper};
      color: ${p => p.theme.input.color};
      font-weight: 500;
    }
  }

  &.sks-search {
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      border-radius: 4px;

      .ant-input {
        font-size: 16px;
      }

      &:hover,
      &:focus {
        border-color: ${p => p.theme.primary};
      }
      &:focus {
        box-shadow: none;
      }
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-color: ${p => p.theme.primary};
    }
  }

  &.placeholder-sm {
    .ant-input {
      ::placeholder {
        font-size: 13px;
      }
    }
  }
`

export default StyledInput
