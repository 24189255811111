import { SVGProps } from 'react'
import cx from 'classnames'

const ChargerSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('tb-icon-charger', className)}
    {...props}>
    <path
      d="M32.9503 12.05L32.967 12.0333L26.767 5.83333L25.0003 7.6L28.517 11.1167C26.9503 11.7167 25.8337 13.2167 25.8337 15C25.8337 15.5472 25.9414 16.089 26.1508 16.5945C26.3602 17.1 26.6671 17.5594 27.054 17.9463C27.8354 18.7277 28.8953 19.1667 30.0003 19.1667C30.6003 19.1667 31.1503 19.0333 31.667 18.8167V30.8333C31.667 31.2754 31.4914 31.6993 31.1788 32.0118C30.8663 32.3244 30.4424 32.5 30.0003 32.5C29.5583 32.5 29.1344 32.3244 28.8218 32.0118C28.5093 31.6993 28.3337 31.2754 28.3337 30.8333V23.3333C28.3337 22.4493 27.9825 21.6014 27.3573 20.9763C26.7322 20.3512 25.8844 20 25.0003 20H23.3337V8.33333C23.3337 7.44928 22.9825 6.60143 22.3573 5.97631C21.7322 5.35119 20.8844 5 20.0003 5H10.0003C9.11627 5 8.26842 5.35119 7.6433 5.97631C7.01818 6.60143 6.66699 7.44928 6.66699 8.33333V35H23.3337V22.5H25.8337V30.8333C25.8337 31.9384 26.2726 32.9982 27.054 33.7796C27.8354 34.561 28.8953 35 30.0003 35C30.5475 35 31.0893 34.8922 31.5948 34.6828C32.1004 34.4734 32.5597 34.1665 32.9466 33.7796C33.3335 33.3927 33.6404 32.9334 33.8498 32.4278C34.0592 31.9223 34.167 31.3805 34.167 30.8333V15C34.167 13.85 33.7003 12.8 32.9503 12.05ZM30.0003 16.6667C29.5583 16.6667 29.1344 16.4911 28.8218 16.1785C28.5093 15.8659 28.3337 15.442 28.3337 15C28.3337 14.558 28.5093 14.134 28.8218 13.8215C29.1344 13.5089 29.5583 13.3333 30.0003 13.3333C30.4424 13.3333 30.8663 13.5089 31.1788 13.8215C31.4914 14.134 31.667 14.558 31.667 15C31.667 15.442 31.4914 15.8659 31.1788 16.1785C30.8663 16.4911 30.4424 16.6667 30.0003 16.6667ZM13.3337 30V22.5H10.0003L16.667 10V18.3333H20.0003L13.3337 30Z"
      fill="#34E8F2"
    />
  </svg>
)

export default ChargerSVG
