import envStore from './../../env'
import { pathOr, trim } from 'ramda'
import React from 'react'

const useCspUtils = () => {
  React.useEffect(() => {
    // 複寫 createElement，當 element 為 style/script 時，自動設置 nonce
    document.createElement = (function (create) {
      return function () {
        // @ts-ignore
        const ret = create.apply(this, arguments)
        if (ret.tagName.toLowerCase() === 'script') {
          ret.setAttribute('nonce', envStore.cspNonce)
        }
        if (ret.tagName.toLowerCase() === 'style') {
          ret.setAttribute('nonce', envStore.cspNonce)
        }
        return ret
      }
    })(document.createElement)
  }, [])

  React.useEffect(() => {
    // 複寫 setAttribute，當 attr 為 style 時，改為透過 el.style = xxx
    // 避免觸發 unsafe-inline 錯誤
    const originalSettAttribute = HTMLElement.prototype.setAttribute
    HTMLElement.prototype.setAttribute = function () {
      const attrName = arguments[0]
      const value = arguments[1]
      if (attrName === 'style') {
        value.split(';').forEach((valueString: string) => {
          const keyValue = valueString.split(':')
          const key = pathOr('', [0], keyValue)
          const value = pathOr('', [1], keyValue)
          this.style[trim(key) as any] = trim(value)
        })
      } else {
        // @ts-ignore
        originalSettAttribute.apply(this, arguments)
      }
    }
  }, [])
}

export default useCspUtils
