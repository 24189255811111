import { useTranslation } from 'react-i18next'
import React from 'react'
import { pathOr } from 'ramda'
import { convertData } from '@shared/innmaxLib/services'
import { momentToString } from '@shared/utils/webHelper'
import { useAllSessions } from '../session'

export const useConvertChargingPayment = (res: any) => {
  const { byId } = useAllSessions()
  const { t } = useTranslation()

  const dataSource = React.useMemo(
    () =>
      convertData<any>({
        converter: x => {
          return {
            ...x,
            deviceTypeStr: x.deviceType && t(`device:${x.deviceType}`),
            createAtStr: momentToString(x.createAt, 'YYYY/MM/DD HH:mm:ss'),
            transStartAtStr: momentToString(
              x.transStartAt,
              'YYYY/MM/DD HH:mm:ss'
            ),
            sessionName: pathOr(
              t('report:no area assigned'),
              [x.sessionId, 'name'],
              byId
            ),
            transTimeStr: (
              Math.round(((x.transTime / (1000 * 60 * 60)) % 24) * 100) / 100
            ).toString(),
          }
        },
      })(res),
    [res]
  )

  return { dataSource }
}
