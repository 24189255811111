import styled from 'styled-components'
import { Input } from 'antd'

const TextArea = styled(Input.TextArea)`
  resize: none;
  border-radius: 4px;
  background-color: ${p => p.theme.input.bg};
  color: ${p => p.theme.input.color};
  border-color: transparent;

  ::placeholder {
    color: ${p => p.theme.input.placeholderColor};
  }

  &:hover,
  &:focus {
    border-color: ${p => p.theme.primary};
  }

  &:focus {
    box-shadow: none;
  }
`

export default TextArea
