import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { toThousandSeparator } from '@shared/utils/webHelper'

import type { ICommonTitle } from './type'
import { css } from 'styled-components'

export default function CommonTitle({
  yAxisDateStr,
  sessionName,
  yAxisName,
  total,
  queryVariables,
}: ICommonTitle) {
  const { t } = useTranslation()

  return (
    <Col span={24} className="mb-22 flex items-center">
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}>
        <div className="flex">
          <div className="ml-8 font-medium text-lg text-primaryYellow">
            {queryVariables?.deviceType
              ? `${t(`device:${queryVariables?.deviceType}`)} /`
              : ''}
          </div>
          {sessionName ? (
            <div className="ml-8 font-medium text-lg text-primaryYellow">
              {`${sessionName} /`}
            </div>
          ) : (
            ''
          )}
          <div className="ml-8 font-medium text-lg text-primaryYellow">{`${yAxisDateStr}`}</div>
          <div className="ml-8 font-medium text-lg text-primaryYellow">
            {yAxisName}
          </div>
          <div
            className="text-[15px] font-medium "
            style={{ color: '#A1DDFF', marginLeft: '10px', marginTop: '2px' }}>
            {t('report:total count', {
              total: toThousandSeparator(total),
            })}
          </div>
        </div>
      </div>
    </Col>
  )
}
