const ChargingPoint = ({ ...props }) => {
  return (
    <i className="icon-charging-point" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none">
        <g opacity="0.7">
          <path
            d="M0.9 2.875C0.9 2.26749 1.39249 1.775 2 1.775H10.4662C11.0737 1.775 11.5662 2.26749 11.5662 2.875V20.225H0.9V2.875Z"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.23321 6.5L4.36328 11H8.10315L6.23321 16.0625"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.2062 3.125L18.6291 5.76392C19.4761 6.68641 19.946 7.89316 19.946 9.14551V18C19.946 19.1046 19.0506 20 17.946 20H16.9596C15.855 20 14.9596 19.1046 14.9596 18V14.5991C14.9596 14.0469 14.5118 13.5991 13.9596 13.5991H12.4663"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="17.75"
            cy="8.75"
            r="1.85"
            stroke="white"
            stroke-width="1.8"
          />
        </g>
      </svg>
    </i>
  )
}

export default ChargingPoint
