import React from 'react'
import { useLeaflet } from './../LeafletProvider'
import { css } from 'styled-components'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

const ZoomControl = React.memo(() => {
  const { map } = useLeaflet()

  return (
    <div
      css={css`
        pointer-events: auto;
        cursor: pointer;
        box-shadow: 0px 1px 5px 2px #00000047;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & div {
          color: #4a4a4a;
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-bottom: 1px solid #4a4a4a;
          }
        }
      `}>
      <div
        onClick={() => {
          if (map) {
            map.setZoom(map.getZoom() + 1)
          }
        }}>
        <PlusOutlined />
      </div>
      <div
        onClick={() => {
          if (map) {
            map.setZoom(map.getZoom() - 1)
          }
        }}>
        <MinusOutlined />
      </div>
    </div>
  )
})

export default ZoomControl
