import React from 'react'
import useLeaflet from '../LeafletProvider'
import styled, { css } from 'styled-components'
import { Tooltip } from '@shared/innmaxUI'
import ToogleIcon from './ToogleIcon'
import { Dropdown } from '@shared/innmaxUI/Dropdown'

const LayerToogle = React.memo(
  (props: { onMapModeChange: (mode: '2D' | '3D') => void }) => {
    const { map, setActiverLayer, mapLayers } = useLeaflet()

    const { onMapModeChange } = props

    const emitMapModeChange = (mode: '2D' | '3D') => {
      onMapModeChange && onMapModeChange(mode)
    }

    return (
      <div>
        <Tooltip title="圖層切換" placement="left">
          <StyledControl
            id="filter-control"
            className="leaflet-control leaflet-bar">
            <Dropdown
              placement="bottomRight"
              menu={[
                {
                  key: 'laser',
                  label: '2D 街景圖',
                  onClick: () => {
                    emitMapModeChange('2D')
                    setActiverLayer(mapLayers[0])
                  },
                  style: { textAlign: 'center' },
                },
                {
                  key: 'photo',
                  label: '2D 正射圖',
                  onClick: () => {
                    emitMapModeChange('2D')
                    setActiverLayer(mapLayers[1])
                  },
                  style: { textAlign: 'center' },
                },
                {
                  key: '3D',
                  label: '3D 地圖',
                  onClick: () => {
                    emitMapModeChange('3D')
                    setActiverLayer(mapLayers[1])
                  },
                  style: { textAlign: 'center' },
                },
              ]}>
              <ToogleIcon />
            </Dropdown>
          </StyledControl>
        </Tooltip>
      </div>
    )
  }
)

const StyledControl = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #084ef8;

  &:hover {
    background-color: #ffcc2b;
    color: #000000;

    svg path {
      stroke: #000000;
    }
  }
`

export default LayerToogle
