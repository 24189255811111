import { SVGProps } from 'react'
import cx from 'classnames'

const DecoYellowLineSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="200"
    height="8"
    viewBox="0 0 200 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('tb-icon-deco-yellow-line', className)}
    {...props}>
    <path
      opacity="0.1"
      d="M4.71698 0H50L45.283 8H0L4.71698 0Z"
      fill="#FFCC2B"
    />
    <path
      opacity="0.3"
      d="M54.717 0H100L95.283 8H50L54.717 0Z"
      fill="#FFCC2B"
    />
    <path
      opacity="0.5"
      d="M104.717 0H150L145.283 8H100L104.717 0Z"
      fill="#FFCC2B"
    />
    <path d="M154.717 0H200L195.283 8H150L154.717 0Z" fill="#FFCC2B" />
  </svg>
)

export default DecoYellowLineSVG
