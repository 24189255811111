import React, { CSSProperties } from 'react'
import { mergeRight, pathOr } from 'ramda'
import {
  CRS,
  Map,
  LatLngExpression,
  LatLngBoundsExpression,
  ControlPosition,
  LeafletMouseEvent,
  MapOptions,
} from 'leaflet'
import {
  ImageOverlay,
  ZoomControl,
  MapContainer,
  useMapEvents,
  useMap,
  WMSTileLayer,
  LayersControl,
} from 'react-leaflet'
import styled from 'styled-components'
import { uuid } from '@shared/innmaxLib/utils/webHelper'
import { defaultMapStyle, defaultLeafletOptions } from '../map.options'
import { LeafletControlsOption, MapType } from '../map.types'
import { useLeaflet } from './LeafletProvider'
import FilterControl from './FilterControl'
import LayerControl from './LayerControl'

import 'leaflet/dist/leaflet.css'

export const defaultMinZoom = -1.0
export const defaultMaxZoom = 1.5

export interface LeafletComponentProps {
  loading?: boolean
  renderLoading?: JSX.Element
  center?: LatLngExpression
  zoom?: number
  children?: ((props: any) => React.ReactNode) | React.ReactNode
  onLoad?: (e: React.MutableRefObject<Map | undefined>) => void
  onClick?: (e: LeafletMouseEvent) => void
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void

  minZoom?: number
  maxZoom?: number
  id?: string
  layerFileUrl: string
  mapContainerStyle?: CSSProperties
  options?: LeafletControlsOption
  scrollWheelZoom?: boolean
  doubleClickZoom?: boolean
  mapType?: MapType
}

const InternalLeaftlet: React.ForwardRefRenderFunction<
  Map,
  LeafletComponentProps
> = (
  {
    layerFileUrl,
    id,
    zoom = 1,
    children,
    onLoad,
    onClick,
    onFilterControlClick,
    onLayerControlClick,
    mapContainerStyle = {},
    options = {},
    loading,
    renderLoading,
    minZoom = defaultMinZoom,
    maxZoom = defaultMaxZoom,
    doubleClickZoom = false,
    scrollWheelZoom = true,
    mapType = MapType.Upload,
    center,
  },
  ref: React.Ref<Map>
) => {
  const mapRef = React.useRef<Map>()

  const [mapBounds, setMapBound] = React.useState<LatLngBoundsExpression>()

  const [imgLoaded, setImgLoaded] = React.useState(false)

  const [containerId] = React.useState(id || `map-${uuid()}`)

  const { handleMapLoad, activedLayer } = useLeaflet()

  const mapOptions = mergeRight(
    {
      ...defaultLeafletOptions,
      filterControlOptions: {
        position: 'topRight' as ControlPosition,
        styles: { margin: '48px 40px 0 0' },
      },
      layerControlOptions: {
        position: 'topRight' as ControlPosition,
        styles: { margin: '95px 40px 0 0' },
      },
      zoomControlOptions: {
        position: 'bottomright' as ControlPosition,
      },
    },
    options
  )

  React.useEffect(() => {
    if (mapType !== MapType.Upload) {
      return
    }
    if (!layerFileUrl) {
      return setImgLoaded(true)
    }
    const img = new Image()
    img.onload = function (this: any) {
      const scale = this.width >= 2000 ? 0.2 : this.width >= 3000 ? 0.1 : 0.3
      setMapBound([
        [this?.height * scale, 0],
        [0, this?.width * scale],
      ])
      setImgLoaded(true)
    }
    img.onerror = function () {
      setMapBound([
        [0, 0],
        [500, 500],
      ])
      setImgLoaded(true)
    }
    img.src = layerFileUrl
  }, [layerFileUrl, mapType])

  const handleWhenReady = React.useCallback(() => {
    onLoad && onLoad(mapRef)
  }, []) //eslint-disable-line

  const renderPlugins = React.useMemo(
    () =>
      [
        {
          name: 'zoomControl',
          component: <ZoomControl {...mapOptions.zoomControlOptions} />,
        },
        {
          name: 'filterControl',
          component: (
            <FilterControl
              onClick={onFilterControlClick}
              {...mapOptions.filterControlOptions}
            />
          ),
        },
        {
          name: 'layerControl',
          component: (
            <LayerControl
              onClick={onLayerControlClick}
              {...mapOptions.layerControlOptions}
            />
          ),
        },
      ]
        .filter(plugin => pathOr(false, [plugin.name], mapOptions))
        .map(plugin =>
          React.cloneElement(plugin?.component, { key: plugin.name })
        ),
    [mapOptions]
  ) //eslint-disable-line

  const eventHandlers = React.useMemo(
    () => ({
      load: () => {
        const el = document.getElementById(containerId)
        if (el) {
          el.style.visibility = 'visible'
          handleMapLoad(mapRef.current as any)
        }
      },
    }),
    []
  ) // eslint-disable-line

  const MapEvents = () => {
    const map = useMap()
    mapRef.current = map
    handleMapLoad(mapRef.current as any)
    useMapEvents({
      click(e: any) {
        onClick && onClick(e)
      },
    })
    return null
  }

  const baseMapProps = React.useMemo<MapOptions>(
    () => ({
      ref,
      id: containerId,
      crs: CRS.Simple,
      bounds: mapBounds,
      maxBounds: mapBounds,
      zoom,
      zoomDelta: 0.5,
      zoomSnap: 0.5,
      maxZoom: maxZoom,
      minZoom: minZoom,
      scrollWheelZoom: scrollWheelZoom,
      doubleClickZoom: doubleClickZoom,
      zoomControl: false,
      attributionControl: false,
      whenReady: handleWhenReady,
      style: mergeRight(defaultMapStyle, mapContainerStyle),
      center,
    }),
    [
      containerId,
      doubleClickZoom,
      handleWhenReady,
      mapBounds,
      mapContainerStyle,
      maxZoom,
      minZoom,
      ref,
      scrollWheelZoom,
      zoom,
      center,
    ]
  )

  if (mapType === MapType.Upload && (loading || !imgLoaded)) {
    return renderLoading ? renderLoading : <div>loading</div>
  }

  if (mapType === MapType.MAP) {
    return (
      <StyleMapContainer
        {...baseMapProps}
        {...options}
        minZoom={1}
        maxZoom={25}>
        <MapEvents />
        <>
          {renderPlugins}
          {children}
          {activedLayer}
        </>
      </StyleMapContainer>
    )
  }

  return mapBounds ? (
    <StyleMapContainer {...baseMapProps} {...options}>
      <MapEvents />
      <ImageOverlay
        url={layerFileUrl}
        bounds={mapBounds}
        eventHandlers={eventHandlers}
      />
      {renderPlugins}
      {typeof children === 'function' ? children({ eventHandlers }) : children}
    </StyleMapContainer>
  ) : (
    <div />
  )
}

const StyleMapContainer = styled(MapContainer)`
  /* visibility: hidden; */
  position: relative;

  .leaflet-control-zoom {
    margin-bottom: 25px;
    margin-right: 40px;
  }
`

export default React.forwardRef<Map, MapProps>(InternalLeaftlet)
