import { Space } from 'antd'
import React from 'react'
import { css } from 'styled-components'
import { LegendContext } from './Provider'

const LegendItem = (props: {
  data: {
    color: string
    name: string
    label?: string
  }
  onClick?: (data: any) => void
  defaultActive?: boolean
}) => {
  const { data, onClick, defaultActive } = props

  const {
    onLegendClick,
    activeNames = [],
    settingActiveName,
  } = React.useContext(LegendContext)

  React.useEffect(() => {
    if (defaultActive) {
      settingActiveName(data.name)
    }
  }, [])

  const actived = activeNames.includes(data.name)

  return (
    <div
      css={css`
        cursor: pointer;
      `}
      onClick={() => onLegendClick(data.name)}>
      <Space>
        <div
          css={css`
            width: 24px;
            height: 12px;
            background-color: ${data.color};
            border-radius: 8px;
          `}
        />
        <span
          css={css`
            font-size: 15px;
            font-weight: 500;
            color: ${actived ? '#fff' : '#999'};
          `}>
          {data.label || data.name}
        </span>
      </Space>
    </div>
  )
}

export default LegendItem
