import { InputNumber } from 'antd'
import styled from 'styled-components'

const StyledInputNumber = styled(InputNumber).attrs({
  min: 0 as any, // dont know why error
})`
  &.ant-input-number {
    border-radius: 4px;
    background-color: ${p => p.theme.input.bg};
    color: ${p => p.theme.input.color};
    border-color: transparent;

    ::placeholder {
      color: ${p => p.theme.input.placeholderColor};
    }

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primary};
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus,
  &.ant-input-number:hover {
    border-color: ${p => p.theme.primary};
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus {
    box-shadow: none;
  }
`

export default StyledInputNumber
