const SubSystem = ({ ...props }) => {
  return (
    <i className="icon-subsystem" {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M2.5 11.5H21.5"
            stroke="#878787"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 3H10C9.73478 3 9.48043 3.10009 9.29289 3.27825C9.10536 3.45641 9 3.69804 9 3.95V7.75C9 8.00196 9.10536 8.24359 9.29289 8.42175C9.48043 8.59991 9.73478 8.7 10 8.7H14C14.2652 8.7 14.5196 8.59991 14.7071 8.42175C14.8946 8.24359 15 8.00196 15 7.75V3.95C15 3.69804 14.8946 3.45641 14.7071 3.27825C14.5196 3.10009 14.2652 3 14 3ZM8 16.3H4C3.73478 16.3 3.48043 16.4001 3.29289 16.5782C3.10536 16.7564 3 16.998 3 17.25V21.05C3 21.302 3.10536 21.5436 3.29289 21.7218C3.48043 21.8999 3.73478 22 4 22H8C8.26522 22 8.51957 21.8999 8.70711 21.7218C8.89464 21.5436 9 21.302 9 21.05V17.25C9 16.998 8.89464 16.7564 8.70711 16.5782C8.51957 16.4001 8.26522 16.3 8 16.3ZM20 16.3H16C15.7348 16.3 15.4804 16.4001 15.2929 16.5782C15.1054 16.7564 15 16.998 15 17.25V21.05C15 21.302 15.1054 21.5436 15.2929 21.7218C15.4804 21.8999 15.7348 22 16 22H20C20.2652 22 20.5196 21.8999 20.7071 21.7218C20.8946 21.5436 21 21.302 21 21.05V17.25C21 16.998 20.8946 16.7564 20.7071 16.5782C20.5196 16.4001 20.2652 16.3 20 16.3Z"
            stroke="#878787"
            stroke-width="1.7"
            stroke-linejoin="round"
          />
          <path
            d="M12 11.5V9M18 15.3V11.5M6 15.3V11.5"
            stroke="#878787"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </i>
  )
}

export default SubSystem
