import { useQuery } from 'react-query'
import { useConvertSessionData } from './session.converter'
import React from 'react'
import { prepend } from 'ramda'
import type { ISession } from '@services/session'
import request from '@shared/innmaxLib/services'
import { useMutation } from 'react-query'
import { usePersistQuery } from '@shared/innmaxLib/services/usePersistQuery'
import { ISessionTreeNode } from './session.types'

export const useAllSessions = () => {
  const { data, ...others } = useQuery('/sessionInfo/list/all', {
    cacheTime: Infinity,
  })

  const sessionData = useConvertSessionData(data)

  const findStoreSession = React.useCallback(
    (id: number) => {
      function loopFind(id: number): ISession | undefined {
        if (!sessionData.byId) {
          return undefined
        }
        if (!sessionData.byId[id]) {
          return undefined
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId && sessionData.byId[parentId].type === 3) {
          return loopFind(parentId)
        }
        return sessionData.byId[id]
      }
      return loopFind(id)
    },
    [sessionData.byId]
  )

  const getFullSessions = React.useCallback(
    (id: number) => {
      function loopFind(id: number, sessions: ISession[]): ISession[] {
        if (!sessionData.byId) {
          return sessions
        }
        if (!sessionData.byId[id]) {
          return sessions
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId) {
          return loopFind(parentId, prepend(sessionData.byId[id], sessions))
        }
        return prepend(sessionData.byId[id], sessions)
      }
      return loopFind(id, [])
    },
    [sessionData.byId]
  )

  return {
    ...others,
    byId: sessionData.byId || {},
    dataSource: sessionData.dataSource,
    data: sessionData.data || [],
    findStoreSession,
    getFullSessions,
  }
}

export const useSessionStatistical = (query?: Record<string, any>) => {
  const { data, ...others } = useQuery(
    ['/sessionInfo/list/statistical', query],
    {
      // refetchInterval: 10 * 1000,
      // refetchOnMount: true,
      // refetchOnMount: true,
    }
  )

  const dataSource = useConvertSessionData(data)

  return {
    ...others,
    dataSource,
  }
}

export const useSessionDetail = (query?: Record<string, any>) => {
  const { data, ...others } = useQuery(['/sessionInfo/detail', query], {
    enabled: !!query?.id
  })
  const dataSource:any = data;

  return {
    ...others,
    dataSource,
  }
}

export const useGetSessionTree = () =>
  usePersistQuery<{ data: ISessionTreeNode[] }>(['/sessionInfo/tree/all'], {
    refetchedErrorCodes: [-51, -52, -53, -54, -55, -56, -57, -58],
  })

export const useAddSession = (options?: any) =>
  useMutation(
    body =>
      request('/sessionInfo', {
        method: 'POST',
        body,
      }),
    options
  )

export const useEditSession = (options?: any) =>
  useMutation(
    body =>
      request('/sessionInfo', {
        method: 'PUT',
        body,
      }),
    options
  )

export const useDeleteSession = (options?: any) =>
  useMutation(
    (body: { id: number }) =>
      request('/sessionInfo', {
        method: 'DELETE',
        body,
      }),
    options
  )

export default useAllSessions
