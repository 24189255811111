import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import { RefProps } from '@lib/types'
import styled from 'styled-components'

type Props = RangePickerProps & RefProps

const StyledDateRangePicker = styled(DatePicker.RangePicker)`
  &.ant-picker {
    border-radius: 4px;
    background-color: ${p => p.theme.input.bg};
    border-color: transparent;

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primary};
    }

    &:focus {
      box-shadow: none;
    }

    > .ant-picker-range-separator {
      span{
        color:#fff; /* Set the range arrow color */
      }
    }

    > .ant-picker-suffix {
      color: #fff;
    }

    > .ant-picker-input{
      > input {
        color: #fff;
      }
    }

    // set clear icon style
    > .ant-picker-range .ant-picker-clear{
      background-color: #284D5D;
      color: #fff;
      font-size: 16px;
    }
  }
`

// for date picker dropdown panel styles
const PanelRenderStyle = styled.div`
  background-color: ${p => p.theme.datePicker.panelBg}; /* Set the background color */
  color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.70));

  .ant-picker-header{
    color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    > button{
      color: ${p => p.theme.datePicker.arrowColor};/* Set the arrow color */
    }
  }

  .ant-picker-panel{
    border : none;
  }

  .ant-picker-cell{
    color: ${p => p.theme.datePicker.panelTextColor}; /* Set the text color */
  }

  .ant-picker-cell-disabled{
    opacity: ${p => p.theme.datePicker.disableOpacity}; /* Set the disabled text opacity */
  }

  .ant-picker-content {
    th{
      color: ${p => p.theme.datePicker.panelTextColor}; /* Set the week text color */
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
      background-color: ${p => p.theme.datePicker.dateRangeBg}; /* Set the range bg color */
      opacity: 0.4; /* Set the range bg color opacity */
    }
  }



`

const RangePicker = React.forwardRef<typeof DatePicker.RangePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <StyledDateRangePicker
      forwardRef={ref}
      className={cx('c-datepicker', className)}
      panelRender={(panel) => (
        <PanelRenderStyle>
          {panel}
        </PanelRenderStyle>
      )}
      {...props}
    />
  )
)

export default RangePicker
