import { ControlPosition } from 'leaflet'

export enum MapLayerType {
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
}

export enum MapType {
  UNKNOWN = 0, // 未知
  TREE = 1, // 節點
  MAP = 2, // 地圖
  Upload = 3, // 圖資
}

export interface GoogleMapControlsOption extends google.maps.MapOptions {
  zoomControl?: null | boolean
  ZoomControlOptions?: null | {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  backRectangleSelectControl?: boolean
  backRectangleSelectControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
    enableRectangleSelect?: boolean
  }
  placeSearchControl?: boolean
  placeSearchControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  filterControl?: boolean
  filterControlOptions?: {
    active: boolean
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
  layerControl?: boolean
  layerControlOptions?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
    iconColor?: string
  }
  satelliteControl?: boolean
  satelliteControlOption?: {
    position?: google.maps.ControlPosition
    styles?: React.CSSProperties
  }
}

export interface LeafletControlsOption {
  zoomControl?: boolean
  zoomControlOptions?: {
    position?: ControlPosition
  }
  filterControl?: boolean
  filterControlOptions?: {
    active: boolean
    position?: ControlPosition
    styles?: React.CSSProperties
  }
  layerControl?: boolean
  layerControlOptions?: {
    position?: ControlPosition
    styles?: React.CSSProperties
  }
  minZoom?: number
  maxZoom?: number
}
