import React from 'react'
import cx from 'classnames'
import { Button } from '@shared/Form'
import theme from '@theme'

import leftIcon from './ic-arrowlight.svg'
import rightIcon from './ic-arrowright.svg'

type Props = {
  page: number
  totalPages: number
  onNextPage: () => any
  onPrevPage: () => any
  className?: string
  style?: object
  children?: React.ReactNode
}

const btnStyle = {
  minWidth: 36,
  padding: '0px',
  borderRadius: '3px',
  border: `solid 1.5px ${theme.btn.bg}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

function Pagination({
  page,
  totalPages,
  onNextPage,
  onPrevPage,
  className,
  ...props
}: Props) {
  const handleNextPage = () => {
    if (onNextPage) {
      onNextPage()
    }
  }

  const handlePrevPage = () => {
    if (onPrevPage) {
      onPrevPage()
    }
  }

  return (
    <div className={cx('flex justify-between', className)} {...props}>
      {page === 1 ? <span></span> : null}
      {totalPages > 1 && page > 1 && (
        <Button type="primary" ghost style={btnStyle} onClick={handlePrevPage}>
          <img src={leftIcon} alt="left" />
        </Button>
      )}
      {totalPages > 1 && page < totalPages && (
        <Button type="primary" ghost style={btnStyle} onClick={handleNextPage}>
          <img src={rightIcon} alt="rigth" />
        </Button>
      )}
    </div>
  )
}

export default React.memo(Pagination)
