import React from 'react'

const Alarm = ({ ...props }) => {
  return (
    <i className="icon-alarm" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9305 10.9245C10.9305 10.3333 11.4098 9.854 12.0011 9.854C12.5923 9.854 13.0716 10.3333 13.0716 10.9245V14.1362C13.0716 14.7274 12.5923 15.2067 12.0011 15.2067C11.4098 15.2067 10.9305 14.7274 10.9305 14.1362V10.9245ZM10.9294 17.3478C10.9294 16.7566 11.4087 16.2773 12 16.2773C12.5912 16.2773 13.0705 16.7566 13.0705 17.3478C13.0705 17.939 12.5912 18.4183 12 18.4183C11.4087 18.4183 10.9294 17.939 10.9294 17.3478Z" />
        <g>
          <path
            d="M4.14323 21.6301H19.8567C20.6146 21.6301 21.3008 21.2425 21.6916 20.5938L4.14323 21.6301ZM4.14323 21.6301C3.77383 21.6317 3.41039 21.537 3.08879 21.3553C2.76719 21.1735 2.49855 20.9111 2.30938 20.5938L4.14323 21.6301ZM20.8979 18.9377L20.8994 18.9407C20.9947 19.1191 21.0419 19.3194 21.0363 19.5216C21.0308 19.7239 20.9727 19.9212 20.8678 20.0942L20.8662 20.0967C20.65 20.4557 20.2771 20.6666 19.8567 20.6666L4.14323 20.6666L4.13892 20.6666C3.93709 20.6675 3.73852 20.6158 3.5628 20.5165C3.38709 20.4172 3.24031 20.2738 3.13696 20.1004L3.13699 20.1004L3.13278 20.0935C3.02783 19.9207 2.96971 19.7236 2.96417 19.5216C2.95863 19.3196 3.00585 19.1196 3.10117 18.9414L3.10313 18.9377L10.9584 4.09756C10.9585 4.09731 10.9586 4.09705 10.9588 4.09679C11.1641 3.71049 11.5619 3.47155 12 3.47155C12.4383 3.47155 12.8365 3.71088 13.0416 4.09666C13.0417 4.09678 13.0418 4.09691 13.0418 4.09703L20.8979 18.9377Z"
            stroke-width="1"
          />
        </g>
      </svg>
    </i>
  )
}

export default Alarm
