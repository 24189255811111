import { Plot } from '@ant-design/charts'
import { concat, remove } from 'ramda'
import React from 'react'

type ILegendContext = {
  filterProp: string
  onLegendClick: (name: string) => void
  activeNames?: string[]
  settingActiveName: (name: string) => void
}

export const LegendContext = React.createContext<ILegendContext>({
  filterProp: '',
  onLegendClick: () => {},
  activeNames: [],
  settingActiveName: () => {},
})

export const LegendProvider = (props: {
  children: React.ReactNode
  filterProp: string
  plotRef?: React.MutableRefObject<Plot<any> | null>
}) => {
  const { children, filterProp } = props
  const [activeNames, setActiveNames] = React.useState<string[]>([])

  React.useEffect(() => {
    if (props.plotRef) {
      props.plotRef.current?.chart.filter(filterProp, (value: string) => {
        return activeNames.includes(value)
      })
      props.plotRef.current?.chart.render(true)
    }
  }, [activeNames, props.plotRef, filterProp])

  const values = React.useMemo<ILegendContext>(() => {
    return {
      activeNames,
      filterProp,
      onLegendClick: (key: string) => {
        setActiveNames(prev => {
          const index = prev.indexOf(key)
          if (index === -1) {
            return concat(prev, [key])
          } else {
            return remove(index, 1, prev)
          }
        })
      },
      settingActiveName: (name: string) => {
        setActiveNames(prev => {
          const index = prev.indexOf(name)
          if (index === -1) {
            return concat(prev, [name])
          }
          return prev
        })
      },
    }
  }, [activeNames, filterProp])

  return (
    <LegendContext.Provider value={values}>{children}</LegendContext.Provider>
  )
}
