import ChartLayout from './ChartLayout'
import Legend from './Legend'

type InternalReport = {
  ChartLayout: typeof ChartLayout
  Legend: typeof Legend
}

const Report: InternalReport = {
  ChartLayout,
  Legend,
}
export default Report
