import { LegendProvider } from './Provider'
import LegendItem from './Item'

type InternalLegend = {
  Provider: typeof LegendProvider
  Item: typeof LegendItem
}

const Legend: InternalLegend = {
  Provider: LegendProvider,
  Item: LegendItem,
}
export default Legend
