import styled from 'styled-components'
import { Form } from 'antd'

const StyledForm = styled(Form)`
  .ant-form-item {
    font-size: 16px;
    color: ${p => p.theme.darkGrey};

    .ant-form-item-label {
      padding: 0 0 4px;

      label {
        font-size: 0.75rem !important;
        height: auto;
        color: ${p => p.theme.form.label};
      }
    }
  }

  .ant-form-item-label > label::after {
    content: '';
  }
  
  .ant-form-item-label:has(.ant-form-item-required) > label::after {
    content: '*';
    color: ${p => p.theme.form.isRequired};
    font-family: PingFang TC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

`

export default StyledForm
