import React from 'react'
import { useChargingPointTypes } from '@services/device'
import { Select, SelectProps } from '@shared/Form/Select'
import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'

export const ChargingPointTypesSelect = React.forwardRef<
  typeof Select,
  SelectProps
>((props, ref): any => {
  const { t } = useTranslation()

  const { dataSource, isLoading } = useChargingPointTypes()
  const _dataSource: any = dataSource

  return (
    <Select
      allowClear
      placeholder={t('device:select ChargingPointTypes')}
      {...props}>
      {(pathOr([], ['data'], _dataSource) || []).map((x: any) => (
        <Select.Option key={x?.types} value={x?.types}>
          {x?.types}
        </Select.Option>
      ))}
    </Select>
  )
})

export default ChargingPointTypesSelect
