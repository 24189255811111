import styled from 'styled-components'

export const TabPane = styled.div`
  display: flex;
  margin-left: -24px;
  margin-top: -24px;

  .tab__item {
    width: 120px;
    height: 32px;
    padding-top: 3px;
    text-align: center;
    border: 1.3px solid ${p => p.theme.primaryBlue};
    font-size: 16px;
    font-weight: 500;
    color: ${p => p.theme.primaryBlue};
    cursor: pointer;
    transition: 0.3s;

    &:not(:first-child){
      border-left:none;
    }

    &:first-child {
      border-bottom-left-radius:4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius:4px;
      border-top-right-radius: 4px;
    }

    &.is--active,
    &:hover {
      background-color:rgba(4, 134, 255, 0.3);
    }
  }
`

export const TabContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  // background-color: rgba(0, 50, 72, 0.3);
  min-height: calc(100vh - 112px);
`
