import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styled from 'styled-components'
import { DomEvent } from 'leaflet'
import LayerIcon from './LayerIcon'
import Tooltip from '@shared/innmaxUI/Tooltip'

export type LayerControlProps = {
  styles?: React.CSSProperties
  onClick?: () => void
  active?: boolean
}

function LayerControl({ styles = {}, onClick, active }: LayerControlProps) {
  const { t } = useTranslation()

  const ref = React.useRef<any>()

  React.useEffect(() => {
    DomEvent?.disableClickPropagation(ref?.current)
  }, [])

  return (
    <div style={styles}>
      <Tooltip title="切換圖層" placement="left">
        <StyledControl
          id="layer-control"
          className="leaflet-control leaflet-bar">
          <button
            ref={ref}
            title={t('dashboard:search device')}
            onClick={onClick}
            className={cx({ active: active })}>
            <LayerIcon color="#fff" />
          </button>
        </StyledControl>
      </Tooltip>
    </div>
  )
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  width: 32px;
  min-height: 32px;
  margin-top: unset !important;
  margin-right: unset !important;
  border: 0 !important;
  background-clip: unset !important;

  button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    color: #fff;
    background-color: #0486FF;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;

    &.active,
    &:hover {
      background-color: #ffcc2b;
      color: #000000;

      svg path {
        fill: #000000;
      }
    }
  }
`

export default React.memo(LayerControl)
