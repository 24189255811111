import React from 'react'
import { useQuery } from 'react-query'
import { path } from 'ramda'
import { QueryVariables } from '@shared/innmaxLib/services'
import { formatDate, isNil } from '@shared/utils/webHelper'

import { ILinkRate } from './report.types'
import { IQueryProps, PAGE_SIZE, TYPE, xAxisName, getLinkRateAverageData } from '@services/report'

export const useCorrectRateChart = (querys: { [key: string]: any }, options?: QueryVariables) => {
  const { data, ...others } = useQuery(['/device/report/dailyRepairDeviceRate', querys], {
    enabled: !isNil(querys),
    ...options,
  })

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return { totalPages: 0, total: 0, content: [] }
    }

    const { reportType } = querys

    const contentData = (path(['data'], data) as ILinkRate[]).map(i => {
      let displayDate: any = ''
      switch (reportType) {
        case TYPE.YEAR:
          const m = new Date(i.date).getMonth()
          displayDate = xAxisName(m)
          break
        case TYPE.MONTH:
          displayDate = `${new Date(i.date).getDate()}日`
          break
        case TYPE.DAY:
          displayDate = formatDate(new Date(`${i.date}:00:00`).valueOf(), 'HH')
          break
        default:
          break
      }

      return { ...i, displayDate }
    })

    const currentTime = new Date().getTime()

    const sourceDate = (date: any) => {
      switch (reportType) {
        case TYPE.YEAR:
        case TYPE.MONTH:
          return new Date(date)
        case TYPE.DAY:
          return new Date(`${date}:00:00`)
        default:
          return new Date()
      }
    }

    const total = contentData.length
    const xAxis = contentData.map(i => i.rate * 100)

    const yAxis = getLinkRateAverageData(
      xAxis.filter((val, index) => (
        currentTime > sourceDate(contentData[index].date).getTime()
      ))
    )

    const content = contentData.map((i, idx: number) => ({
      ...i,
      rate: i.rate * 100,
      avgRate: yAxis[idx],
    }))

    return {
      xAxis,
      yAxis,
      xAxisLabels: contentData.map(i => i.date),
      total,
      totalPages: Math.ceil(total / PAGE_SIZE),
      content,
    }
  }, [data, querys])

  return {
    dataSource,
    ...others,
  }
}

export const useExportCorrectRateRpt = (query?: IQueryProps, options?: QueryVariables) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    [
      '/device/streetlight/report/lampDailyLinkRate/export',
      { deep: true, ...query, pageNum: undefined, pageSize: undefined },
    ],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
