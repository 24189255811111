import React from 'react'
import { Dropdown as AntDropdown } from 'antd'
import { DropDownProps } from 'antd/lib/dropdown'
import type { MenuItemType } from 'rc-menu/lib/interface'

interface EnhancementMenuItemType extends MenuItemType {
  onClick?: () => any
}

interface Props extends Omit<DropDownProps, 'menu'> {
  menu: EnhancementMenuItemType[]
  children: JSX.Element
}

const Dropdown = ({
  menu,
  children,
  overlay,
  overlayStyle,
  trigger = ['click'],
  ...props
}: Props) => {
  const items = [
    ...menu.map(i => ({
      key: i?.key,
      label: <div onClick={i.onClick} style={i.style} className={i.className}>{i.label}</div>,
      disabled: i?.disabled
    })),
  ]

  return (
    <AntDropdown
      overlayClassName="text-center g-dropdown"
      getPopupContainer={() => document.getElementById('root') as any}
      trigger={trigger}
      menu={{ items }}
      overlayStyle={{
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.2)',
        textAlign: 'right',
        ...overlayStyle,
      }}
      {...props}>
      {children}
    </AntDropdown>
  )
}

export default Dropdown
