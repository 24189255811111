import React from 'react'
import { compose, map, pathOr } from 'ramda'
import { convertData } from '@shared/innmaxLib/services'
import { convertMS } from '@shared/utils/webHelper'
import env from '@shared/env'

import { DeviceTypeId } from '@services/constants'
import { useConvertData, IPhotoDto, ILamp } from '@services/device'
import {
  IElectricityMeter,
  IElectricityMeterDetail,
} from './electricitymeter.types'

export const useConvertElectricityMeters = (res: any) => {
  const {
    deviceStatus,
    activeStr,
    sessionName,
    expiredDate,
    installTime,
    updateTime,
    locationNs,
  } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<IElectricityMeter>({
        converter: x => {
          return {
            ...x,
            ...deviceStatus(x),
            ...activeStr(x),
            ...sessionName(x),
            ...expiredDate(x),
            ...installTime(x),
            ...updateTime(x),
            ...locationNs(x),
            properties: {
              ...x.properties,
            },
          }
        },
      })(res),
    [res]
  )

  return dataSource
}

export const useConvertElectricityMeterDetail = (
  x?: IElectricityMeterDetail
) => {
  const {
    deviceStatus,
    activeStr,
    sessionName,
    expiredDate,
    installTime,
    updateTime,
    lastOnlineAt,
    expiredAt,
    installAt,
    switchLeakageStr,
    locationNs,
    sessionPath,
  } = useConvertData()

  return React.useMemo(() => {
    if (!x) {
      return {}
    }

    const lamps = pathOr([], ['properties', 'lamps'], x)

    return {
      ...x,
      ...deviceStatus(x),
      ...activeStr(x),
      ...sessionName(x),
      ...expiredDate(x),
      ...installTime(x),
      ...updateTime(x),
      ...locationNs(x),
      ...sessionPath(x),
      deviceTypeId: DeviceTypeId[x.deviceType],

      lampCount: lamps.length,
      properties: {
        ...x.properties,
        ...switchLeakageStr(x),
        lamps: lamps.map((i: ILamp) => {
          return {
            ...i,
            ...deviceStatus(i),
            ...activeStr(i),
            ...lastOnlineAt(i),
            ...expiredAt(i),
            ...installAt(i),
            deviceId: x.deviceId,
            extra: {
              ...i.extra,
              time: convertMS(i.extra.usedSec),
            },
            properties: {
              ...i.properties,
              imgs: compose(
                map((y: IPhotoDto) => ({
                  ...y,
                  url: `${env.uploadFileUrlPrefix}${y.filePath}`,
                }))
              )(i.properties.imgs),
            },
          }
        }),
      },
    }
  }, [x])
}
