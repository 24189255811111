import { Input } from 'antd'
import styled from 'styled-components'

const StyledInputPassword = styled(Input.Password)`
    border:transparent;
    background-color: ${p => p.theme.input.bg};

    .ant-input{
        background-color: transparent !important;
        color: ${p => p.theme.input.color};
    }

    .ant-input-password-icon {
        color: ${p => p.theme.input.color};
    }
`

export default StyledInputPassword