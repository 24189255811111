import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import {
  path,
  uniqBy,
  pipe,
  filter,
  isNil,
  propEq,
  pathOr,
  isEmpty,
} from 'ramda'
import {
  convertData,
  DataSource,
  QueryVariables,
  useLazyQuery,
  MutationOptions,
} from '@shared/innmaxLib/services'
import { request, useMakeMutation } from '@shared/innmaxLib/services'

import {
  Device,
  useDeviceConverter,
  useConvertIDevice,
  useLazySubDevice,
} from '@services/device'

const { compose } = require('ramda')

const fakeDevices = [
  {
    id: 1,
    displayName: '路燈001',
    deviceId: '路燈001',
    deviceType: 'streetlight',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.249308650617866,
    lon: 120.509225610890169,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '路燈001',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
  {
    id: 2,
    displayName: '路燈002',
    deviceId: '路燈002',
    deviceType: 'streetlight',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.229182466613505,
    lon: 120.4790684289951,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '路燈002',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
  {
    id: 3,
    displayName: '路燈003',
    deviceId: '路燈003',
    deviceType: 'streetlight',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.26779855281275,
    lon: 120.51771736739049,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '路燈003',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
  {
    id: 4,
    displayName: '碼頭照明001',
    deviceId: '碼頭照明001',
    deviceType: 'pierlight',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.268732580635625,
    lon: 120.49507504758701,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '碼頭照明001',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
  {
    id: 5,
    displayName: '水錶001',
    deviceId: '水錶001',
    deviceType: 'watermeter',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.22449888401831,
    lon: 120.49883464239815,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '水錶001',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
  {
    id: 6,
    displayName: '電錶001',
    deviceId: '電錶001',
    deviceType: 'electricitymeter',
    customName: null,
    address: null,
    sessionId: 2,
    lat: 24.21334278374888,
    lon: 120.47056776368714,
    x: 121,
    y: -24,
    active: 1,
    installTime: null,
    expiredDate: 1689438819000,
    updateTime: 1689438819000,
    remark: null,
    isPositioned: true,
    alarmCount: 0,
    repairCount: 0,
    parentDeviceType: null,
    parentDeviceSeq: null,
    parentDeviceDisplayName: null,
    subDeviceCount: 0,
    subAlarmCount: 0,
    subRepairCount: 0,
    properties: {
      imgs: [],
      lightNo: '電錶001',
      switchLeakage: null,
      switchboxSeq: null,
      switchboxDisplayName: null,
      lightGroupName: null,
      taipowerGrid: null,
      poleStyle: null,
      poleMaterial: null,
      lamps: [],
    },
    extra: {},
    isAlarm: false,
    isRepair: false,
    isSubAlarm: false,
    isSubRepair: false,
  },
]

export const useQueryMultipleDevice = () => {
  const convertDevice = useDeviceConverter()

  const fetchMultipleDevice = (devices = []) => {
    const tasks = pipe(
      filter((d: any) => d && !isNil(d.deviceType) && !isNil(d.id)),
      uniqBy((d: any) => d.id && d.deviceType)
    )(devices).map((d: any) => request(`/device/${d.deviceType}/${d.id}`))
    return Promise.all(tasks).then(result => {
      return result.map(r => convertDevice(path(['data'], r) as any))
    })
  }

  return {
    fetchMultipleDevice,
  }
}

export const useLazyQueryDevice = (options: QueryVariables = {}) => {
  const convertDevice = useDeviceConverter()

  const {
    isLoading: isFetchingSubDevice,
    refetch: fetchSubDevice,
    data: subDevice,
  } = useLazySubDevice(undefined)

  // const [fakeQueryDevice, setFakeQueryDevice] = React.useState<Device>(
  //   fakeDevices[0] as any as Device
  // )

  const { refetch, data, ...rest } = useLazyQuery('/device/{deviceType}/{id}', {
    ...options,
    select: res => path(['data'], res),
    keepPreviousData: true,
    onSuccess: (device: any) => {
      device &&
        device.subDeviceCount &&
        fetchSubDevice({
          parentDeviceSeq: device?.id,
          parentDeviceType: device?.deviceType,
        })
      return options.onSuccess && options.onSuccess(convertDevice(device))
    },
  })

  const device: Device | any = React.useMemo(() => (data ? data : null), [data]) //eslint-disable-line

  const subDevices: Device | unknown = React.useMemo(
    () => (data ? subDevice : null),
    [subDevice] //eslint-disable-line
  )

  const dataSource = React.useMemo(
    () =>
      data
        ? compose(convertDevice)({
            ...device,
            ...(device.subDeviceCount ? { subDevices } : null),
          })
        : null,
    [subDevices, device] //eslint-disable-line
  )

  return {
    ...rest,
    data: dataSource,
    // data: fakeQueryDevice,
    loading: rest.isLoading || isFetchingSubDevice,
    loadingData: rest.isLoading || isFetchingSubDevice,
    queryDevice: refetch,
    // queryDevice: (varaible: any, isChild?: boolean) => {
    //   console.log('varaible', varaible)
    //   setFakeQueryDevice(
    //     compose(
    //       pathOr(undefined, [0]),
    //       filter(propEq('id', varaible?.id))
    //     )(fakeDevices) as any
    //   )
    // },
  }
}

export const useDeviceInfos = <T extends Device>({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const convertDevice = useDeviceConverter()

  const [fakeQueryDevices, setFakeQueryDevices] = React.useState(fakeDevices)

  const { refetch, data, ...others } = useLazyQuery(
    [
      '/device/sampleDeviceInfos',
      { current: '', size: '', isSub: false, ...variables },
    ],
    {
      ...options,
      keepPreviousData: true,
    }
  )

  // const dataSource: DataSource<T> = React.useMemo(
  //   () =>
  //     convertData<T>(
  //       {
  //         converter: convertDevice,
  //       },
  //       { data: fakeQueryDevices }
  //     ),
  //   [fakeQueryDevices] //eslint-disable-line
  // )

  const dataSource: DataSource<T> = React.useMemo(
    () =>
      convertData<T>(
        {
          converter: convertDevice,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  const devicesBySessionId = React.useMemo(() => {
    const area: any[] = []

    dataSource?.content
      ?.filter((d: any) => d.lat && d.lon)
      .forEach(i => {
        if (i.sessionPath.length > 2) {
          if (!area[i.sessionId as number]) {
            area[i.sessionId as number] = []
          }
          area[i.sessionId as number].push(i)
        }

        const sencondSessionId = i?.sessionPath[1]?.id
        if (sencondSessionId) {
          if (!area[sencondSessionId as number]) {
            area[sencondSessionId as number] = []
          }
          area[sencondSessionId as number].push(i)
        }
      })

    return area
  }, [dataSource])

  return {
    ...others,
    refetch,
    loading: others.isLoading || !others.dataUpdatedAt,
    queryDeviceInfos: refetch,
    // queryDeviceInfos: (variables: any, keepPreviousQuery?: boolean) => {
    //   const input = variables?.input || ''
    //   const filterByDevices = variables?.deviceType || []
    //   let fakeResult = fakeDevices

    //   if (filterByDevices.length > 0) {
    //     fakeResult = filter((device: Device) =>
    //       device.deviceType.includes(filterByDevices)
    //     )(fakeDevices as any)
    //   }
    //   if (!isEmpty(input)) {
    //     fakeResult = filter((device: Device) =>
    //       device.deviceId.includes(input)
    //     )(fakeDevices as any)
    //   }

    //   setFakeQueryDevices(fakeResult)
    //   options.onSuccess &&
    //     options.onSuccess({
    //       data: fakeResult,
    //     })
    // },
    dataSource: dataSource?.content || [],
    devicesBySessionId,
  }
}

/**
 *  設備清單
 * @param deep:是否搜尋區域深層
 */
export const useDevices = <T extends Device>(
  query?: {
    [key: string]: any
  },
  options?: UseQueryOptions
) => {
  const convertDevice = useDeviceConverter()

  const { data, ...rest } = useQuery(
    ['/device/{deviceType}/{pageNum}/{pageSize}', { deep: true, ...query }],
    { cacheTime: 0, ...options }
  )

  const dataSource: DataSource<T> = React.useMemo(
    () =>
      convertData<T>(
        {
          converter: convertDevice,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  return {
    ...rest,
    dataSource,
    data,
    loading: rest.isLoading,
  }
}

export const useDevice = ({
  deviceType,
  id,
}: {
  deviceType: string
  id?: React.Key
}) => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/device/{deviceType}/{id}`, { deviceType, id }],
    { enabled: !!id, cacheTime: 0, select: res => path(['data'], res) }
  )

  const { data: deviceData } = useConvertIDevice(data)

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: deviceData,
  }
}

export const createDeviceUrl = '/device/{deviceType}'

export const createDevice = (values: Partial<any>) =>
  request(createDeviceUrl, { method: 'POST', body: values })

export const useAddDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(createDevice, options)
}

export const deleteDevice = (values: { deviceType: string; id: React.Key }) =>
  request(`/device/{deviceType}/{id}`, { method: 'DELETE', body: values })

export const updateDeviceUrl = '/device/{deviceType}/{id}'

export const updateDevice = (values: Partial<any> = {}) =>
  request(updateDeviceUrl, {
    method: 'PUT',
    body: values,
  })

export const useUpdateDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(updateDevice, options)
}

export const useDeleteDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteDevice, options)
}

export const useDeviceExport = (query?: any, options?: QueryVariables) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    ['/device/{deviceType}/export', { ...query }],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
