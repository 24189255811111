import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'
import { Alarm } from '@services/alarm'
import { useAllSessions } from '@services/session'
import moment from 'moment'
import { DeviceType, DeviceTypeId } from '@services/constants'
import {
  STREETLIGHT_MANAGEMENT,
  CAMERA_MANAGEMENT,
  SWITCHBOX_MANAGEMENT,
  MAP_DASHBOARD,
  POWERSUPPLIER_MANAGEMENT,
  MEDIA_SCREEN_MANAGEMENT,
  WEATHER_MANAGEMENT,
} from 'src/routes'
import { useAlarmTypes } from '@services/alarm'

export const useAlarmConverter = () => {
  const { t } = useTranslation()

  const { byId } = useAllSessions()

  const { alarmTypeByKey } = useAlarmTypes()

  const convertCreateAt = (alarm: Alarm) => ({
    createAt: moment(alarm.createAt),
    createAtStr: moment(alarm.createAt).format('YYYY.MM.DD HH:mm:ss'),
  })

  const convertCloseAt = (alarm: Alarm) => ({
    closeAt: moment(alarm.closeAt),
    closeAtStr: moment(alarm.closeAt).format('YYYY.MM.DD HH:mm:ss'),
  })

  const convertAlarmType = (alarm: Alarm) => ({
    alarmTypeDesc: alarmTypeByKey[alarm.alarmType]?.desc,
  })

  const convertAlarmSession = (alarm: Alarm) => ({
    sessionName: pathOr('', [alarm.sessionId, 'name'], byId),
  })

  const convertDeviceTypeId = (alarm: Alarm) => ({
    deviceTypeId: DeviceTypeId[alarm.deviceType],
  })

  const convertDeviceTypeStr = (alarm: Alarm) => ({
    deviceTypeStr: t(`device:${alarm.deviceType}`),
  })

  const convertItemUrls = (alarm: Alarm) => {
    const deviceTypeId = convertDeviceTypeId(alarm).deviceTypeId
    let url = null
    switch (alarm.deviceType) {
      case DeviceType.LIGHT:
        url = STREETLIGHT_MANAGEMENT
        break
      case DeviceType.CAMERA:
        url = CAMERA_MANAGEMENT
        break
      case DeviceType.POWERSUPPLIER:
        url = POWERSUPPLIER_MANAGEMENT
        break
      case DeviceType.DISPLAYSCREEN:
        url = MEDIA_SCREEN_MANAGEMENT
        break
      case DeviceType.WEATHERSTATION:
        url = WEATHER_MANAGEMENT
        break
    }
    return {
      mapDashboardUrl: `${MAP_DASHBOARD}?device=${deviceTypeId},${alarm.deviceSeq}`,
      detailPageUrl: `${url}?device=${deviceTypeId},${alarm.deviceSeq}`,
    }
  }

  return (item: Alarm) => ({
    ...item,
    ...(item.createAt && convertCreateAt(item)),
    ...(item.closeAt && convertCloseAt(item)),
    ...(item.sessionId && convertAlarmSession(item)),
    ...(item.alarmType && convertAlarmType(item)),
    ...(item.deviceType && convertDeviceTypeStr(item)),
    ...convertDeviceTypeId(item),
    ...convertItemUrls(item),
  })
}
